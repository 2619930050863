import axios from "axios";

// ? /\ Value /\
import { API_LINK } from "configlink";
import { getALlInfoForUser } from "redux/Function/actions";

// ? /\ Types /\
import { client } from "redux/types";

// ? /\ Actions /\
import { setMessage, setSpinner } from "redux/UI/actions";


// ? Log a client In
export const LogClientIn = (username, password, history) => dispacth => {
    dispacth(setSpinner(true))
    axios.post(`${API_LINK}login`, {
        username: username,
        password: password
    })
        .then((resp) => {
            console.log(resp.data.data)
            dispacth({
                type: client.LOGIN,
                payload: resp.data.data,
                token: resp.data.token
            });
            dispacth(setSpinner(false))
            if (resp.data.data.isAdmin) {
                history.push("/admin")
            } else {
                dispacth(getALlInfoForUser())
                history.push("/client")
            }
        }).catch((err) => {
            dispacth(setSpinner(false))
            console.log("error from login", err)
        })
}

export const imageCreation = (job, groupe, cat, id) => dispacth => {
console.log("here")
    dispacth(setSpinner(true))
    axios.post(`${API_LINK}user/img/creation`, {
            job: job,
            cat: cat,
            groupe: groupe,
            id: id
    }).then((res) => {
      dispacth( createAFeed(res.data, groupe, id))
    }).catch((err) => {
        console.log(err)
        dispacth(setSpinner(false))
        dispacth(setMessage("Une erreur est survenue"))
    })
}
export const createAFeed = (img, groupe, id) => dispacth => {
    console.log(img, groupe)
  axios.post(`${API_LINK}feed/post`, {
        link: img,
        groupe: groupe, 
        clientId: id
    }).then((res) => {
        dispacth(setSpinner(false))
        dispacth(setMessage("Nous avons bien pris en compte votre demande les images seront posté sur vos réseaux"))
    }).catch((err) => {
        console.log(err)
        dispacth(setSpinner(false))
        dispacth(setMessage("Une erreur est survenue"))
    }) 
}
export const logOut = (history) => dispacth => {
    dispacth({
        type: client.LOGOUT
    })
    history.push("/")
}

