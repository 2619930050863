import React from 'react';

// ? /\ Styles /\
import styles from 'components/abstract/BTN/index.module.css';

function Btn(props) {

    // ? /\ Props /\
    const {onClick, Txt} = props
    
    return (
        <>
            <button className={styles.LogBox_RightSide_BTN} onClick={onClick} >{Txt}</button>
        </>
    )
}
export default Btn;