import { ui } from "redux/types";
const initialState = {
        isLoading: false,
        message: null,
        SearchResultPage: 1,
        resultPageIndexMax: 9,
        resultPageIndexMin: 0
}

const reducer = (state = initialState, action) => {
        switch (action.type) {
                case ui.SET_MESSAGE:
                        return {
                                ...state,
                                message: action.payload,
                        };
                case ui.SET_SPINNER:
                        return {
                                ...state,
                                loading: action.payload,
                        };
                case ui.RESET_MESSAGE:
                        return {
                                ...state,
                                message: null,
                        };
                case ui.SET_RESULT_PAGE:
                        return {
                                ...state,
                                SearchResultPage: action.payload
                        };
                case ui.SET_RESULT_PAGE_INDEX:
                        return {
                                ...state,
                                resultPageIndexMax: action.max,
                                resultPageIndexMin: action.min
                        };
                default:
                        return state;
        }
}
export default reducer;