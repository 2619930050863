import React, { useEffect, useState } from 'react';

// ? /\ Style /\
import styles from 'components/concrete/Image/img_list.module.css';

// ? /\ Redux /\
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

// ? /\ Actions /\
import { deleteAnImage, getAllImage, getAllInfo, createAnImage } from 'redux/Function/actions';
import { setMessage } from 'redux/UI/actions'

// ? /\ JSX /\
import LoadingIcon from 'components/abstract/LoadingIcon';
import Btn from 'components/abstract/BTN';

// ? /\ React Icons /\
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from 'react-icons/io';

// ? /\ React Icons /\
import { IoMdCloseCircle } from 'react-icons/io'

function Image() {

    // ? /\ Store /\
    const func = useSelector(state => state.fonctionel);
    const client = useSelector(state => state.client);
    const ui = useSelector(state => state.ui)

    // ? /\ Other Actions /\
    const dispatch = useDispatch();

    // ? /\ State /\
    const [number, setNumber] = useState(0);
    const [showPopUp, setShowPopUp] = useState()
    const [name, setName] = useState("")
    const [image, setImgae] = useState(null)
    const [cat, setCat] = useState(null)

    useEffect(() => {
        if (!func.allGroupe || !func.allImg || !func.allJob || !func.allJobCat || !func.allRegion || !func.allUser) {
            dispatch(getAllInfo()); // ? to get all info if we don't have it
        } if (ui.message === "L'image a bien été crée") {// ? If images is created clear
            setName("")
            setImgae(null)
        }
    }, [func, ui]);

    // ? /\ Create Images functions /\
    function register() {
        if (!name) {
            dispatch(setMessage("Vous n'avez pas défini le nom"))
        } else if (!image) {
            dispatch(setMessage("Vous n'avez pas défini l'image"))
        } else {
            dispatch(createAnImage(name, image, client.token, cat))
        }
    }


    // ? CHange images Function
    function changeImage(value, curent) {
        if (value === "+") {
            if (number + 1 > func.allImg.length - 1) {
                setNumber(0)
            } else {
                setNumber(curent + 1)
            }
        } if (value === "-") {
            if (number - 1 < 0) {
                setNumber(func.allImg.length - 1)
            } else {
                setNumber(curent - 1)
            }
        }
    }
    // ? Show PopUp Function
    function FuncShowPopUp() {
        if (showPopUp === true) {
            setShowPopUp(false);
        } else {
            setShowPopUp(true);
        }
    }

    return (
        <>
            <div className={styles.Image_Main}>
                <div className={styles.Image_Box}>
                    <div className={styles.Image_Title}>
                        <div className={styles.Image_Up}>
                            <p className={styles.Image_Title_Txt} >Liste des images</p>
                            <Btn Txt={"Ajouter une image"} onClick={FuncShowPopUp} />
                        </div>
                        <div className={styles.Image_Loading_And_Page}>
                            <LoadingIcon onClick={() => { dispatch(getAllImage()) }} />
                        </div>
                    </div>
                    <div className={styles.Image_Separator} ></div>
                    <div className={styles.Image_List_Box}>
                        <div className={styles.ImgList_Main_Img_Box}>
                            <div className={styles.ImgList_Main_left_BTN}>
                                <button className={styles.ImgList_Main_Btn} onClick={() => { changeImage("+", number) }}><IoIosArrowDropleftCircle size={25} color={"white"} /></button>
                            </div>
                            <div className={styles.ImgList_Main_Img_section}>
                                <h3  style={{ color: "white", marginBottom: "15px" }}>{func.allImg.length > 0 ? func.allImg[number].name : null}</h3>
                                <img style={{marginBottom: "25px"}} src={func.allImg.length > 0 ? func.allImg[number].url : null} className={styles.ImgList_Main_IMG} />
                                <Btn Txt={"Supprimer"}   onClick={() => { dispatch(deleteAnImage(func.allImg.length > 0 ? func.allImg[number].id : null, client.token)) }}/>
                            </div>
                            <div className={styles.ImgList_Main_Right_BTN}>
                                <button className={styles.ImgList_Main_Btn} onClick={() => { changeImage("-", number) }}><IoIosArrowDroprightCircle size={25} color={"white"} /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                showPopUp ? (
                    <>
                        <div className={styles.Image_PopUp_Main_Box}>
                            <div className={styles.Image_PopUp_CloseBtn}> <p className={styles.Image_PopUp_All_Txt}>Creation Utilisateur</p> <IoMdCloseCircle onClick={() => { setShowPopUp(false) }} color='white' size={30} /> </div>
                            <div className={styles.Image_PopUp_Top_Bar}>
                                <div className={styles.ImageRegister_FBox}>
                                    <div className={styles.ImageRegister_Form}>
                                        <div className={styles.ImageRegister_Flexy}>
                                            <p className={styles.ImageRegister_label} >Nom de l'image:</p>
                                            <input className={styles.ImageRegister_Input2} placeholder='Nom' type="text" value={name} onChange={(e) => { setName(e.target.value) }} />
                                        </div>
                                        <div className={styles.ImageRegister_Flexy} style={{ marginTop: 25, marginBottom: 25 }}>
                                            <p className={styles.ImageRegister_label} >Votre image:</p>
                                            <input className={styles.ImageRegister_InputImage} accept="image/*" type="file" onChange={(e) => { setImgae(e.target.files[0]) }} />
                                            <img src={image ? URL.createObjectURL(image) : null} style={{ width: "200px", marginTop: "25px" }} />
                                            <select onChange={(e) => { setCat(e.target.value) }} className={styles.Select} style={{ padding: '10px' }}>
                                                <option value={null} selected >Choisissez une catégorie</option>
                                                {func.allJobCat?.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item._id}>{item.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <Btn Txt={"Enregistrer l'utilisateur"} onClick={register} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
                    :
                    null
            }
        </>
    );
}

export default Image;