import React from "react";

// ? /\ Styles /\
import styles from "./pagination.module.css";

// ? /\ JSX /\
import Icon from "../Icon";

// ? /\ Images /\
import chevron from "static/img/chevron.png";

// ? /\ Actions /\
import { setResultPage } from "redux/UI/actions";

// ? /\ Redux /\
import { useDispatch } from "react-redux";

function Pagination(props) {

	// ? /\ Props /\
	const { amountOfPages, currentPage, onClickLeft, onClickRight } = props;

	// ? /\ Other actions /\
	const dispatch = useDispatch();

	// ? /\ Value /\
	const pagesLeftToShow = amountOfPages - currentPage;
	const size = 5 + (2 - Math.min(pagesLeftToShow, 2));
	const pagesToShow = Array.from({ length: Math.min(amountOfPages, size) }, (_, i) => i + Math.max(currentPage - (size - 1), 1));
	
	return (
		<div className={styles.container}>
			<Icon src={chevron} className={styles.arrow_left} iconClassName={styles.arrow_symbol} size={36} onClick={onClickLeft} />
			<div className={styles.pages_container}>
				{pagesToShow.map((pageNumber, i) => (
					<div onClick={() => dispatch(setResultPage(pageNumber))} key={i} className={styles.page + " " + (pageNumber === currentPage ? styles.page_active : "")}>
						<span className={styles.page_number}>{pageNumber}</span>
					</div>
				))}
				{pagesLeftToShow > 4 ? (
					<div className={styles.page}>
						<span className={styles.page_number}>...</span>
					</div>
				) : null}
				{pagesLeftToShow > 4 ? (
					<div className={styles.page} onClick={() => dispatch(setResultPage(amountOfPages))}>
						<span className={styles.page_number}>{amountOfPages}</span>
					</div>
				) : null}
			</div>
			<Icon src={chevron} className={styles.arrow_right} iconClassName={styles.arrow_symbol} size={36} onClick={onClickRight} />
		</div>
	);
}

export default Pagination;
