import React from "react";

// ? /\ Styles /\
import styles from "./number_tag.module.css";

function NumberTag(props) {

	// ? /\ Props /\
	const { children } = props;

	return (
		<div className={styles.container}>
			<span className={styles.text + " poppins-medium-white-10px"}>{children}</span>
		</div>
	);
}

export default NumberTag;
