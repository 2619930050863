import React, { useEffect, useState } from 'react';

// ? /\ Styles /\
import styles from 'components/concrete/JobsCat/user_list.module.css';

// ? /\ JSX /\
import Pagination from 'components/abstract/Pagination';
import LoadingIcon from 'components/abstract/LoadingIcon';
import Btn from 'components/abstract/BTN';

// ? /\ Redux /\
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

// ? /\ Actions /\
import { deleteAJobCat, getAllJobCat, createAJobCat, modifyJobCat } from 'redux/Function/actions';
import { nextResultPage, prevResultPage, setResultPageIndex, setMessage } from 'redux/UI/actions';

// ? /\ React Icons /\
import { IoMdCloseCircle } from 'react-icons/io'

function JobsCat() {

    // ? /\ Store /\
    const func = useSelector(state => state.fonctionel);
    const client = useSelector(state => state.client);
    const ui = useSelector(state => state.ui);

    // ? /\ Other Actions /\
    const dispatch = useDispatch();

    // ? /\ Value /\
    const resultByPage = 9;

    // ? /\ State /\
    const [groupeList, setGroupeList] = useState();
    const [intialize, setIntialize] = useState();
    const [showPopUp, setShowPopUp] = useState();
    const [name, setName] = useState("");
    const [showMorePopUp, setShowMorePopUp] = useState(false);
    const [toShow, setToShow] = useState([]);
    const [searchInput, setSearchInput] = useState("")

    useEffect(() => {
        if (!func.allUser && !intialize) {
            dispatch(getAllJobCat())
        } else if (!groupeList && func.allJobCat && !intialize) {
            console.log("in")
            setIntialize(true)
            setGroupeList(func.allJobCat.slice(ui.resultPageIndexMin, ui.resultPageIndexMax));
        } else if (ui.SearchResultPage * resultByPage !== ui.resultPageIndexMax) {
            console.log("inside where i xant")
            dispatch(setResultPageIndex(resultByPage, ui.SearchResultPage))
        } if (intialize && groupeList !== func.allJobCat.slice(ui.resultPageIndexMin, ui.resultPageIndexMax)) {
            console.log("inside where i want 2")
            setGroupeList(func.allJobCat.slice(ui.resultPageIndexMin, ui.resultPageIndexMax));
        } if (ui.message === "La catégorie a bien été crée") {
            setName(""); // ? if cat create reset 
        }
    }, [func, ui]);

    // ? /\ Register JobCat functions /\
    function register() {
        if (!name) {
            dispatch(setMessage("Vous n'avez pas défini la catégorie"))
        } else {
            dispatch(createAJobCat(name, client.token))
        }
    }

    // ? Function to show PopUp
    function FuncShowPopUp() {
        if (showPopUp === true) {
            setShowPopUp(false);
        } else {
            setShowPopUp(true);
        }
    }

    // ? List Of Cat with pages system 
    const renderList = groupeList?.map((item, index) => {
        return (
            <div className={styles.JobCat_List} key={index} >
                <p className={styles.JobCat_Color} onClick={() => { showMore(index, showMorePopUp) }} >{item.name}</p>
                <Btn Txt={"Supprimer"}   onClick={() => { dispatch(deleteAJobCat(item._id, client.token)) }} />
            </div>
        )
    });
    const searchList = func.allJobCat?.map((item, index) => {
        if(item.name.toLowerCase().includes(searchInput.toLowerCase())){

            return (
                <div className={styles.JobCat_List} key={index} >
                    <p className={styles.JobCat_Color} onClick={() => { showMore(index, showMorePopUp) }} >{item.name}</p>
                    <Btn Txt={"Supprimer"}   onClick={() => { dispatch(deleteAJobCat(item._id, client.token)) }} />
                </div>
        )
    }
    });
    function showMore(index, value) {
        if (value) {
            setShowMorePopUp(false);
            setToShow([])
        } else if (!value) {
            setShowMorePopUp(true);
            setToShow(func.allJobCat[ui.SearchResultPage > 1 ? (ui.SearchResultPage - 1) * resultByPage + index : index])
        }
    }
    function ModifyJobCatFunc(){
        dispatch(modifyJobCat(client.id, client.token, toShow))
    }


    return (
        <>
            <div className={styles.JobCat_Main}>
                <div className={styles.JobCat_Box}>
                    <div className={styles.JobCat_Title}>
                        <div className={styles.JobCat_Up}>
                            <p className={styles.JobCat_Title_Txt} >Liste des catégories</p>
                            <Btn Txt={"Ajouter une catégorie"} onClick={FuncShowPopUp} />
                        </div>
                        <div className={styles.search_box}>
                            <input className={styles.search_bar} value={searchInput} onChange={(e) => {setSearchInput(e.target.value)}} />
                        </div>
                        <div className={styles.JobCat_Loading_And_Page}>
                            <LoadingIcon onClick={() => { dispatch(getAllJobCat()) }} />
                            <Pagination
                                amountOfPages={Math.ceil(func.allJobCat?.length / resultByPage)}
                                currentPage={ui.SearchResultPage}
                                onClickLeft={() => dispatch(prevResultPage(ui.SearchResultPage, Math.ceil(func.allJobCat.length / resultByPage), 1))}
                                onClickRight={() => dispatch(nextResultPage(ui.SearchResultPage, Math.ceil(func.allJobCat.length / resultByPage), 1))}
                            />
                        </div>
                    </div>
                    <div className={styles.JobCat_Separator} ></div>
                    <div className={styles.JobCat_List_Box}>
                        <div className={styles.JobCat_List} >
                            <p className={styles.JobCat_Color}>nom</p>
                            <p className={styles.JobCat_Color}>Supprimer</p>
                        </div>
                        <div className={styles.JobCat_Separator} ></div>
                        {searchInput ? searchList : renderList}

                    </div>
                </div>
            </div>
            {showMorePopUp ?
                <>
                    <div className={styles.JobCat_PopUp_Main_Box}>
                        <div className={styles.JobCat_PopUp_CloseBtn}> <p className={styles.JobCat_PopUp_All_Txt}>Panel Utilisateur</p> <IoMdCloseCircle onClick={() => { showMore(0, true) }} color='white' size={30} /> </div>
                        <div style={{ marginTop: "25px" }} className={styles.JobCat_PopUp_Top_Bar}>
                            <p style={{ marginTop: "25px" }} className={styles.JobCatRegister_label} >Nom</p>
                            <input className={styles.JobCatRegister_Input} placeholder="Nom" type="text" value={toShow.name} onChange={(e) => { setToShow({ ...toShow, name: e.target.value }) }} />
                        </div>
                        <div style={{ marginTop: "100px" }} className={styles.JobCat_PopUp_Person}>
                            <Btn Txt={"Modifier"} onClick={ModifyJobCatFunc} />
                        </div>
                    </div>
                </>
                :
                null
            }
            {
                showPopUp ? (
                    <>
                        <div className={styles.JobCat_PopUp_Main_Box}>
                            <div className={styles.JobCat_PopUp_CloseBtn}> <p className={styles.JobCat_PopUp_All_Txt}>Creation Utilisateur</p> <IoMdCloseCircle onClick={() => { setShowPopUp(false) }} color='white' size={30} /> </div>
                            <div className={styles.JobCat_PopUp_Top_Bar}>
                                <div className={styles.JobCatRegister_FBox}>
                                    <div autoComplete='off' className={styles.JobCatRegister_Form}>
                                        <div style={{ marginBottom: "25px" }}>
                                            <p className={styles.JobCatRegister_label} >Nom de catégorie:</p>
                                            <input className={styles.JobCatRegister_Input} placeholder='Nom de la catégorie (ex Publicité et Marketing)' type="text" value={name} onChange={(e) => { setName(e.target.value) }} />
                                        </div>
                                        <Btn Txt={"Enregistrer l'utilisateur"} onClick={register} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
                    :
                    null
            }
        </>
    );
}

export default JobsCat;