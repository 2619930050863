// ? /\ Redux /\
import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

// ? /\ Reducer /\
import clientsReducer from "redux/Client/reducer";
import uiReducer from "redux/UI/reducer";
import fonctionelReducer from "redux/Function/reducer";


const reducer = combineReducers({
	client: clientsReducer,
	ui: uiReducer,
	fonctionel: fonctionelReducer,
});

const store = createStore(reducer, applyMiddleware(thunk));

export default store;
