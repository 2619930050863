// ? /\ Types /\
import { ui } from "redux/types";

// ? Set Message
export const setMessage = (message) => (dispatch) => {
	dispatch({ type: ui.SET_MESSAGE, payload: message });
};
// ? Set Spinner
export const setSpinner = (bool) => (dispatch) => {
	dispatch({ type: ui.SET_SPINNER, payload: bool });
};
// ? Reset Message
export const resetMessage = () => (dispatch) => {
	dispatch({ type: ui.RESET_MESSAGE });
};
// ? Next Result Page
export const nextResultPage = (current, max, min) => dispatch => {
	if (current && max && min) {
		if (current + 1 <= max) {
			dispatch({
				type: ui.SET_RESULT_PAGE,
				payload: current + 1
			})
		} else if (current + 1 === max) {
			dispatch({
				type: ui.SET_RESULT_PAGE,
				payload: max
			})
		} else if (current + 1 === max + 1) {
			dispatch({
				type: ui.SET_RESULT_PAGE,
				payload: min
			})
		}
	}
}
// ? Previous Result Page
export const prevResultPage = (current, max, min) => dispatch => {
	if (current && max && min) {
		if (current - 1 >= min) {
			dispatch({
				type: ui.SET_RESULT_PAGE,
				payload: current - 1
			})
		} else if (current - 1 > min) {
			dispatch({
				type: ui.SET_RESULT_PAGE,
				payload: min
			})
		} else if (current - 1 === min - 1) {
			dispatch({
				type: ui.SET_RESULT_PAGE,
				payload: max
			})
		}
	}
}
// ? Set Result Page
export const setResultPage = (page) => dispatch => {
	if (page) {
		dispatch({
			type: ui.SET_RESULT_PAGE,
			payload: page
		})
	}
}
// ? Set Result Page Index
export const setResultPageIndex = (resultByPage, page) => async dispatch => {
	if (resultByPage && page) {
		const max = resultByPage * page
		const min = max - resultByPage
		if (page === 1) {
			dispatch({
				type: ui.SET_RESULT_PAGE_INDEX,
				max: 9,
				min: 0
			})
		} else if (max && min) {
			dispatch({
				type: ui.SET_RESULT_PAGE_INDEX,
				max: max,
				min: min
			})
		}
	}
}