import React, { useEffect, useState } from 'react';

// ? /\ Styles /\
import styles from 'components/concrete/Groupe/user_list.module.css';

// ? /\ Redux /\
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

// ? /\ Actions /\
import { deleteAGroupe, getAllGroupe, getAllInfo, createGroupe, modifyGroupe } from 'redux/Function/actions';
import { nextResultPage, prevResultPage, setResultPageIndex, setMessage } from 'redux/UI/actions';

// ? /\ JSX /\
import Pagination from 'components/abstract/Pagination';
import LoadingIcon from 'components/abstract/LoadingIcon';
import Btn from 'components/abstract/BTN';

// ? /\ React Icons /\
import { IoMdCloseCircle } from 'react-icons/io';

function Groupe() {

    // ? /\ Store /\
    const func = useSelector(state => state.fonctionel);
    const client = useSelector(state => state.client);
    const ui = useSelector(state => state.ui);

    // ? /\ Other Function /\
    const dispatch = useDispatch();

    // ? /\ Value /\
    const resultByPage = 9;

    // ? /\ State /\
    const [groupeList, setGroupeList] = useState();
    const [intialize, setIntialize] = useState();
    const [showPopUp, setShowPopUp] = useState();
    const [town, setTown] = useState("");
    const [region, setRegion] = useState("");
    const [name, setName] = useState("");
    const [showMorePopUp, setShowMorePopUp] = useState(false);
    const [toShow, setToShow] = useState([]);
    const [searchInput, setSearchInput] = useState("")

    useEffect(() => {
        if (!func.allGroupe || !func.allImg || !func.allJob || !func.allJobCat || !func.allRegion || !func.allUser && !intialize) {
            dispatch(getAllInfo()); // ? to get all info if we don't have it
        } else if (!groupeList && func.allGroupe && !intialize) {
            setIntialize(true)
            setGroupeList(func.allGroupe.slice(ui.resultPageIndexMin, ui.resultPageIndexMax)); // ? Set first GroupeList to have renderList
        } else if (ui.SearchResultPage * resultByPage !== ui.resultPageIndexMax) {
            dispatch(setResultPageIndex(resultByPage, ui.SearchResultPage)); // ? if we change page set new idex ex : at page 2 10 --> 19
        } else if (intialize && groupeList !== func.allGroupe.slice(ui.resultPageIndexMin, ui.resultPageIndexMax)) {
            setGroupeList(func.allGroupe.slice(ui.resultPageIndexMin, ui.resultPageIndexMax)); // ? if not same set the new Grouplist to render it
        } else if (ui.message === "Le groupe a bien été enregistrer") { // ? reset all info if we create the groupe
            setTown("");
            setName("");
        }
    }, [func, ui]);

    // ? /\ To show the popup /\
    function FuncShowPopUp() {
        if (showPopUp === true) {
            setShowPopUp(false);
        } else {
            setShowPopUp(true);
        }
    }

    // ? /\ Register fucntion  /\
    function register() {
        if (!town) {
            dispatch(setMessage("Les deux mot de passe ne coresspond pas "))
        } else if (!name) {
            dispatch(setMessage("Vous n'avez pas défini la region"))
        } else if (!region) {
            dispatch(setMessage("Vous n'avez pas défini l'email"))
        } else {
            dispatch(createGroupe(town, name, region, client.token))
        }
    }
    // ? Show modify Groupe PopUp
    function showMore(index, value) {
        if (value) {
            setShowMorePopUp(false);
            setToShow([])
        } else if (!value) {
            setShowMorePopUp(true);
            setToShow(func.allGroupe[ui.SearchResultPage > 1 ? (ui.SearchResultPage - 1) * resultByPage + index : index])
        }
    }
    // ? /\ Map of groupe to have page system /\
    const renderList = groupeList?.map((item, index) => {
        return (
            <div className={styles.Groupe_List} key={index} >
                <p className={styles.Groupe_Color} onClick={() => { showMore(index, showMorePopUp) }} >{item.name}</p>
                <p className={styles.Groupe_Color} onClick={() => { showMore(index, showMorePopUp) }} >{item.town}</p>
                <Btn Txt={"Supprimer"} onClick={() => { dispatch(deleteAGroupe(item._id, client.token)) }} />
            </div>
        )
    });
    const searchList = func.allGroupe?.map((item, index) => {
        if(item.name.toLowerCase().includes(searchInput.toLowerCase())){

            return (
<div className={styles.Groupe_List} key={index} >
                <p className={styles.Groupe_Color} onClick={() => { showMore(index, showMorePopUp) }} >{item.name}</p>
                <p className={styles.Groupe_Color} onClick={() => { showMore(index, showMorePopUp) }} >{item.town}</p>
                <Btn Txt={"Supprimer"} onClick={() => { dispatch(deleteAGroupe(item._id, client.token)) }} />
            </div>
        )
    }
    });

    function ModifyGroupeFunc() {
        dispatch(modifyGroupe(client.id, client.token, toShow)) 
    }


    return (
        <>
            <div className={styles.Groupe_Main}>
                <div className={styles.Groupe_Box}>
                    <div className={styles.Groupe_Title}>
                        <div className={styles.Groupe_Up}>
                            <p className={styles.Groupe_Title_Txt} >Liste des Groupes</p>
                            <Btn Txt={"Ajouter un groupe"} onClick={FuncShowPopUp} />
                        </div>
                        <div className={styles.search_box}>
                            <input className={styles.search_bar} value={searchInput} onChange={(e) => {setSearchInput(e.target.value)}} />
                        </div>
                        <div className={styles.Groupe_Loading_And_Page}>
                            <LoadingIcon onClick={() => { dispatch(getAllGroupe()) }} />
                            <Pagination
                                amountOfPages={Math.ceil(func.allGroupe?.length / resultByPage)}
                                currentPage={ui.SearchResultPage}
                                onClickLeft={() => dispatch(prevResultPage(ui.SearchResultPage, Math.ceil(func.allGroupe.length / resultByPage), 1))}
                                onClickRight={() => dispatch(nextResultPage(ui.SearchResultPage, Math.ceil(func.allGroupe.length / resultByPage), 1))}
                            />
                        </div>
                    </div>
                    <div className={styles.Groupe_Separator} ></div>
                    <div className={styles.Groupe_List_Box}>
                        <div className={styles.Groupe_List} >
                            <p className={styles.Groupe_Color}>nom</p>
                            <p className={styles.Groupe_Color}>Ville</p>
                            <p className={styles.Groupe_Color}>Supprimer</p>
                        </div>
                        <div className={styles.Groupe_Separator} ></div>
                        {searchInput ? searchList : renderList}

                    </div>
                </div>
            </div>
            {showMorePopUp ?
                <>
                    <div className={styles.Groupe_PopUp_Main_Box}>
                        <div className={styles.Groupe_PopUp_CloseBtn}> <p className={styles.Groupe_PopUp_All_Txt}>Panel Utilisateur</p> <IoMdCloseCircle onClick={() => { showMore(0, true) }} color='white' size={30} /> </div>
                        <div style={{ marginTop: "25px" }} className={styles.Groupe_PopUp_Top_Bar}>
                            <p style={{ marginTop: "25px" }} className={styles.GroupeRegister_label} >Nom</p>
                            <input className={styles.GroupeRegister_Input} placeholder="Nom" type="text" value={toShow.name} onChange={(e) => { setToShow({ ...toShow, name: e.target.value }) }} />
                            <p style={{ marginTop: "25px" }} className={styles.GroupeRegister_label} >ville :</p>
                            <input className={styles.GroupeRegister_Input} placeholder="Ville" type="text" value={toShow.town} onChange={(e) => { setToShow({ ...toShow, town: e.target.value }) }} />
                            <p className={styles.GroupeRegister_label} >Region :</p>
                            <select onChange={(e) => { setToShow({ ...toShow, region: e.target.value }) }} value={toShow.region} className={styles.Select} style={{ padding: '10px' }}>
                                <option value={null} selected >Choisissez un groupe</option>
                                {func.allRegion?.map((item, index) => {
                                    return (
                                        <option key={index} value={item._id}>{item.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        <div style={{ marginTop: "100px" }} className={styles.Groupe_PopUp_Person}>
                            <Btn Txt={"Modifier"} onClick={ModifyGroupeFunc} />
                        </div>
                    </div>
                </>
                :
                null
            }
            {
                showPopUp ? (
                    <>
                        <div className={styles.Groupe_PopUp_Main_Box}>
                            <div className={styles.Groupe_PopUp_CloseBtn}> <p className={styles.Groupe_PopUp_All_Txt}>Creation Groupes</p> <IoMdCloseCircle onClick={() => { setShowPopUp(false) }} color='white' size={30} /> </div>
                            <div className={styles.Groupe_PopUp_Top_Bar}>
                                <div className={styles.GroupeRegister_FBox}>
                                    <div autoComplete='off' className={styles.GroupeRegister_Form}>
                                        <div>
                                            <p className={styles.GroupeRegister_label} >Ville :</p>
                                            <input className={styles.GroupeRegister_Input} placeholder='Nom de la ville (ex Paris)' type="numeric" value={town} onChange={(e) => { setTown(e.target.value) }} />
                                        </div>
                                        <div>
                                            <p className={styles.GroupeRegister_label} >Nom du groupe BNI :</p>
                                            <input className={styles.GroupeRegister_Input} placeholder='Groupe BNI' type="text" value={name} onChange={(e) => { setName(e.target.value) }} />
                                        </div>
                                        <div className={styles.GroupeRegister_Flexy} >
                                            <p className={styles.GroupeRegister_label} >Region :</p>
                                            <select onChange={(e) => { setRegion(e.target.value) }} className={styles.Select} style={{ padding: '10px' }}>
                                                <option value={null} selected >Choisissez un groupe</option>
                                                {func.allRegion?.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item._id}>{item.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <Btn Txt={"Enregistrer l'utilisateur"} onClick={register} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
                    :
                    null
            }
        </>
    );
}

export default Groupe;