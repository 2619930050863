import React, { useEffect, useState } from 'react';

// ? /\ Styles /\
import styles from 'components/concrete/User/user_list.module.css';

// ? /\ JSX /\
import Pagination from 'components/abstract/Pagination';
import LoadingIcon from 'components/abstract/LoadingIcon';
import Moment from 'react-moment';
import Btn from 'components/abstract/BTN';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

// ? /\ Redux /\
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

// ? /\ Actions /\
import { deleteAClient, GetAllclient, createClient, modifyUser } from 'redux/Function/actions';
import { nextResultPage, prevResultPage, setMessage, setResultPageIndex } from 'redux/UI/actions';

// ? /\ MUI /\
import Checkbox from '@mui/material/Checkbox';
import { red } from '@mui/material/colors';

// ? /\ React Icons /\
import { IoMdCloseCircle } from 'react-icons/io';
import { GoPerson } from 'react-icons/go';
import { IoEyeOff, IoEye } from 'react-icons/io5'

// ? /\ Others /\
import 'moment-timezone';
import axios from 'axios';
import { API_LINK } from 'configlink';
function User() {
    /* 
     todo:  Ajout a effecter : Système pagination, dernière utilisation(1426)
    */

    // ? /\ Store /\
    const func = useSelector(state => state.fonctionel);
    const client = useSelector(state => state.client);
    const ui = useSelector(state => state.ui);

    // ? /\ Other Actions /\
    const dispatch = useDispatch();

    // ? /\ Value /\
    const resultByPage = 9;

    // ? /\ State /\
    const [showMorePopUp, setShowMorePopUp] = useState(false);
    const [toShow, setToShow] = useState([]);
    const [showPopUp, setShowPopUp] = useState()
    const [isAdmin, setIsAdmin] = useState(false);
    const [region, setRegion] = useState(null);
    const [multiRegion, setMultiRegion] = useState([]);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [surname, setSurname] = useState("");
    const [name, setName] = useState("");
    const [groupe, setGroupe] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isMulti, setIsMulti] = useState(false);
    const [multiGroupe, setMultiGroupe] = useState([]);
    const [UserList, setUserList] = useState();
    const [intialize, setIntialize] = useState();
    const [searchInput, setSearchInput] = useState("")

    useEffect(() => {
        if (!func.allUser) {
            dispatch(GetAllclient());
        }else if (!UserList && func.allUser && !intialize) {
            console.log("in")
            setIntialize(true)
            setUserList(func.allUser.slice(ui.resultPageIndexMin, ui.resultPageIndexMax));
        } else if (ui.SearchResultPage * resultByPage !== ui.resultPageIndexMax) {
            console.log("inside where i xant")
            dispatch(setResultPageIndex(resultByPage, ui.SearchResultPage))
        } if (intialize && UserList !== func.allUser.slice(ui.resultPageIndexMin, ui.resultPageIndexMax)) {
            console.log("inside where i want 2")
            setUserList(func.allUser.slice(ui.resultPageIndexMin, ui.resultPageIndexMax));
        }
        if (ui.message === "L’utilisateur a bien été enregistré") {
            setIsAdmin(false); // ? reset all info if user is created
            setRegion(null);
            setPassword("");
            setConfirmPassword("");
            setEmail("");
            setGroupe(null);
            setName("");
            setSurname("");
            setUsername("");
        }
    }, [func, ui]);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    // ? /\ Register User function /\
    function register() {
        if (password.length < 6) {
            dispatch(setMessage("Le mot de passe n'est pas assez long"));
        } else if (password !== confirmPassword) {
            dispatch(setMessage("Les deux mot de passe ne coresspond pas "));
        } else if (!email) {
            dispatch(setMessage("Vous n'avez pas défini l'email"));
        } else if (!surname) {
            dispatch(setMessage("Vous n'avez pas défini le nom"));
        } else if (!name) {
            dispatch(setMessage("Vous n'avez pas défini le prénom"));
        } else if (isMulti) {
            if (!multiRegion) {
                dispatch(setMessage("Vous n'avez pas défini la region / les regions"));
            } else if (!multiGroupe) {
                dispatch(setMessage("Vous n'avez pas défini les groupes"));
            } else {
                dispatch(createClient(email, password, multiRegion, isAdmin, username, multiGroupe, surname, name, client.token));
            }
        } else if (!region) {
            dispatch(setMessage("Vous n'avez pas défini la region"));
        } else {
            dispatch(createClient(email, password, region, isAdmin, username, groupe, surname, name, client.token));
        }
    }
    // ? Show User Info PopUp
    function showMore(index, value) {
        if (value) {
            setShowMorePopUp(false);
            setToShow([])
        } else if (!value) {
            setShowMorePopUp(true);
            setToShow(func.allUser[index])
        }
    }
    // ? Show Register User PopUp
    function FuncShowPopUp() {
        if (showPopUp === true) {
            setShowPopUp(false);
        } else {
            setShowPopUp(true);
        }
    }

    function ModifyUserFunc() {
        dispatch(modifyUser(client.id, client.token, toShow))
    }
    const renderList = UserList?.map((item, index) => {
        return (
            <div className={styles.User_List} key={index}  >
                                    <p className={styles.User_Color} onClick={() => { showMore(index, showMorePopUp) }} >{item.username}</p>
                                    <p className={styles.User_Color} onClick={() => { showMore(index, showMorePopUp) }} >{item.region?.map((item, index) => { return item.name + ", "; })}</p>
                                    <p className={styles.User_Color} onClick={() => { showMore(index, showMorePopUp) }} >{item.groupe?.map((item, index) => { return item.name + ", "; })}</p>
                                    <p className={styles.User_Color} onClick={() => { showMore(index, showMorePopUp) }} ><Moment date={item.lastActivity} format="DD/MM/YYYY" tz="Europe/Paris" /> à <Moment date={item.lastActivity} format="hh:mm" tz="Europe/Paris" /></p>
                                    <Btn Txt={"Supprimer"} onClick={() => { dispatch(deleteAClient(item._id, client.token)) }} />
                                </div>
        )
    });
    const searchList = func.allUser?.map((item, index) => {
        if(item.username.toLowerCase().includes(searchInput.toLowerCase())){

            return (
                <div className={styles.User_List} key={index}  >
                <p className={styles.User_Color} onClick={() => { showMore(index, showMorePopUp) }} >{item.username}</p>
                <p className={styles.User_Color} onClick={() => { showMore(index, showMorePopUp) }} >{item.region?.map((item, index) => { return item.name + ", "; })}</p>
                <p className={styles.User_Color} onClick={() => { showMore(index, showMorePopUp) }} >{item.groupe?.map((item, index) => { return item.name + ", "; })}</p>
                <p className={styles.User_Color} onClick={() => { showMore(index, showMorePopUp) }} ><Moment date={item.lastActivity} format="DD/MM/YYYY" tz="Europe/Paris" /> à <Moment date={item.lastActivity} format="hh:mm" tz="Europe/Paris" /></p>
                <Btn Txt={"Supprimer"} onClick={() => { dispatch(deleteAClient(item._id, client.token)) }} />
            </div>
        )
    }
    });

    return (
        <>
            <div className={styles.User_Main}>
                <div className={styles.User_Box}>
                    <div className={styles.User_Title}>
                        <div className={styles.User_Up}>
                            <p className={styles.User_Title_Txt} >Liste des utilisateurs</p>
                            <Btn Txt={"Ajouter un utilisateur"} onClick={FuncShowPopUp} />
                        </div>
                        <div className={styles.search_box}>
                            <input className={styles.search_bar} value={searchInput} onChange={(e) => {setSearchInput(e.target.value)}} />
                        </div>
                        <div className={styles.User_Loading_And_Page}>
                            <LoadingIcon onClick={() => { dispatch(GetAllclient()) }} />
                            <Pagination 
                            amountOfPages={Math.ceil(func.allUser?.length / resultByPage)}
                            currentPage={ui.SearchResultPage}
                            onClickLeft={() => dispatch(prevResultPage(ui.SearchResultPage, Math.ceil(func.allUser.length / resultByPage), 1))}
                            onClickRight={() => dispatch(nextResultPage(ui.SearchResultPage, Math.ceil(func.allUser.length / resultByPage), 1))}
                            />
                        </div>
                    </div>
                    <div className={styles.User_Separator} ></div>
                    <div className={styles.User_List_Box}>
                        <div className={styles.User_List} >
                            <p className={styles.User_Color}>Username</p>
                            <p className={styles.User_Color}>Region</p>
                            <p className={styles.User_Color}>Groupe</p>
                            <p className={styles.User_Color}>Dernière connexion</p>
                            <p className={styles.User_Color}>Supprimer</p>
                        </div>
                        <div className={styles.User_Separator} ></div>
                        {searchInput ? searchList : renderList}
                    </div>
                </div>
            </div>
            {showMorePopUp ?
                <>
                    <div className={styles.User_PopUp_Main_Box}>
                        <div className={styles.User_PopUp_CloseBtn}> <p className={styles.User_PopUp_All_Txt}>Panel Utilisateur</p> <IoMdCloseCircle onClick={() => { showMore(0, true) }} color='white' size={30} /> </div>
                        <div className={styles.User_PopUp_Person}><GoPerson color="white" size={60} /></div>

                        <div className={styles.User_PopUp_Top_Bar}>
                            <p className={styles.UserRegister_label} >Nom d'utilisateur :</p>
                            <input className={styles.UserRegister_Input2} placeholder="Nom d'utilisateur" type="text" value={toShow.username} onChange={(e) => { setToShow({ ...toShow, username: e.target.value }) }} />
                            <p className={styles.UserRegister_label} >adresse email :</p>
                            <input className={styles.UserRegister_Input2} placeholder="adresse email" type="mail" value={toShow.email} onChange={(e) => { setToShow({ ...toShow, email: e.target.value }) }} />
                            <div className={styles.UserRegister_Flex_Path}>
                                <p className={styles.UserRegister_label}>Utilisateur Admin :</p>
                                <Checkbox sx={{ color: red[800], '&.Mui-checked': { color: red[600], }, }} onChange={(e) => { setToShow({ ...toShow, isAdmin: e.target.checked }) }} checked={toShow.isAdmin} />
                            </div>
                            <p className={styles.User_PopUp_All_Txt}>Region : {toShow.region.map((item, index) => { return item.name + ", "; })}</p>
                            <p className={styles.User_PopUp_All_Txt}>Département : {toShow.region.map((item, index) => { return item.zip + " " + item.area + ", "; })}</p>
                            <p className={styles.User_PopUp_All_Txt}>Directeur de region : {toShow.region.map((item, index) => { return item.directeur + ", "; })}</p>
                            <p className={styles.User_PopUp_All_Txt}>Groupe : {toShow.groupe.map((item, index) => { return item.name + ", "; })}</p>
                            <p className={styles.User_PopUp_All_Txt}>Ville : {toShow.groupe.map((item, index) => { return item.town + ", "; })}</p>
                            <p className={styles.User_PopUp_All_Txt}>Dernière utilisation : <Moment date={toShow.lastUse} format="DD/MM/YYYY" tz="Europe/Paris" /> à <Moment date={toShow.lastUse} format="hh:mm" tz="Europe/Paris" /></p>  {/*  // ! 1426 a implémenter  */}
                            <p className={styles.User_PopUp_All_Txt}>Dernière connexion : <Moment date={toShow.lastActivity} format="DD/MM/YYYY" tz="Europe/Paris" /> à <Moment date={toShow.lastActivity} format="hh:mm" tz="Europe/Paris" /></p>
                            <div className={styles.User_Image_Edit_Link}>
                            <p className={styles.User_PopUp_All_Txt} style={{marginRight: "10px"}}>liste des image : </p>
                            {toShow.imageEdit?.map((item, index) => {
                                return(
                                    <a key={index} href={item}  target="_blank" className={styles.User_Image_Edit_A} >Image{index}</a>
                                    )
                                })}
                                </div>
                        </div>
                        <div className={styles.User_PopUp_Person}>
                            <Btn Txt={"Modifier"} onClick={ModifyUserFunc} />
                        </div>
                    </div>
                </>
                :
                null
            }
            {
                showPopUp ? (
                    <>
                        <div className={styles.User_PopUp_Main_Box}>
                            <div className={styles.User_PopUp_CloseBtn}> <p className={styles.User_PopUp_All_Txt}>Creation Utilisateur</p> <IoMdCloseCircle onClick={() => { setShowPopUp(false) }} color='white' size={30} /> </div>
                            <div className={styles.User_PopUp_Top_Bar}>
                                <div className={styles.UserRegister_Form}>
                                    <div className={styles.UserRegister_Display}>
                                        <div className={styles.UserRegister_Flexy} >
                                            <p className={styles.UserRegister_label} >Adresse email:</p>
                                            <input className={styles.UserRegister_Input2} placeholder='email' type="email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                        </div>
                                        <div className={styles.UserRegister_Flexy} >
                                            <p className={styles.UserRegister_label} >Nom d'utilisateur:</p>
                                            <input className={styles.UserRegister_Input2} placeholder='Username' type="text" value={username} onChange={(e) => { setUsername(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className={styles.UserRegister_Display}>
                                        <div className={styles.UserRegister_Flexy} >
                                            <p className={styles.UserRegister_label} >Nom:</p>
                                            <input className={styles.UserRegister_Input2} placeholder='nom' type="text" value={surname} onChange={(e) => { setSurname(e.target.value) }} />
                                        </div>
                                        <div className={styles.UserRegister_Flexy} >
                                            <p className={styles.UserRegister_label} >Prénom:</p>
                                            <input className={styles.UserRegister_Input2} placeholder='Prénom' type="text" value={name} onChange={(e) => { setName(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className={styles.UserRegister_Display}>
                                        <div className={styles.UserRegister_Flexy} >
                                            <p className={styles.UserRegister_label} >Mot de passe:</p>
                                            <input className={styles.UserRegister_Input2} placeholder='Mot de passe' type={showPassword ? "text" : "password"} value={password} onChange={(e) => { setPassword(e.target.value) }} />
                                            {showPassword ? <IoEye className={styles.test} onClick={() => { setShowPassword(false) }} /> : <IoEyeOff className={styles.test} onClick={() => { setShowPassword(true) }} />}
                                        </div>
                                        <div className={styles.UserRegister_Flexy} >
                                            <p className={styles.UserRegister_label} >Confirmation du mot de passe:</p>
                                            <input className={styles.UserRegister_Input2} placeholder='Confirmation' type={showConfirmPassword ? "text" : "password"} value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} />
                                            {showConfirmPassword ? <IoEye className={styles.test} onClick={() => { setShowConfirmPassword(false) }} /> : <IoEyeOff className={styles.test} onClick={() => { setShowConfirmPassword(true) }} />}
                                        </div>
                                    </div>
                                    <div className={styles.UserRegister_Display} style={{ marginBottom: "15px" }}>
                                        <div className={styles.UserRegister_Flexy_Check} >
                                            <p className={styles.UserRegister_label}>Utilisateur Admin :</p>
                                            <Checkbox sx={{ color: red[800], '&.Mui-checked': { color: red[600], }, }} onChange={() => { setIsAdmin(isAdmin => !isAdmin) }} checked={isAdmin} />
                                        </div>
                                        <div className={styles.UserRegister_Flexy_Check} >
                                            <p className={styles.UserRegister_label}>Multiple groupe:</p>
                                            <Checkbox sx={{ color: red[800], '&.Mui-checked': { color: red[600], }, }} onChange={() => { setIsMulti(isMulti => !isMulti) }} checked={isMulti} />
                                        </div>
                                    </div>
                                    {isMulti ?
                                        (
                                            <>
                                                <div className={styles.Select_Flex}>
                                                    <div className={styles.Select_Flex_R_Wide}>
                                                        <p className={styles.UserRegister_label} >Region :</p>
                                                        <Select
                                                            className={styles.UserRegister_Input_Multi}
                                                            id="demo-multiple-chip"
                                                            multiple
                                                            value={multiRegion}
                                                            placeholder={"Choisissez une region"}
                                                            onChange={(e) => { setMultiRegion(e.target.value) }}
                                                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                                            renderValue={(selected) => (
                                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                    {selected.map((value) => (
                                                                        func.allRegion?.map((item, index) => {
                                                                            if (value === item._id)
                                                                                return (
                                                                                    <Chip key={value} label={item.name} />
                                                                                )
                                                                        })

                                                                    ))}
                                                                </Box>
                                                            )}
                                                            MenuProps={MenuProps}
                                                        >
                                                            {func.allRegion?.map((item, index) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={index}
                                                                        value={item._id}
                                                                    >
                                                                        {item.name}
                                                                    </MenuItem>
                                                                )
                                                            })}

                                                        </Select>
                                                    </div>
                                                </div>
                                                <div className={styles.Select_Flex}>
                                                    <div className={styles.Select_Flex_R_Wide}>
                                                        <p className={styles.UserRegister_label} >Groupe :</p>
                                                        <Select
                                                            className={styles.UserRegister_Input_Multi}
                                                            id="demo-multiple-chip"
                                                            multiple
                                                            value={multiGroupe}
                                                            placeholder={"Choisissez une region"}
                                                            onChange={(e) => { setMultiGroupe(e.target.value) }}
                                                            input={<OutlinedInput id="select-multiple-chip" label="Groupe" />}
                                                            renderValue={(selected) => (
                                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                    {selected.map((value) => (
                                                                        func.allGroupe?.map((item, index) => {
                                                                            if (value === item._id)
                                                                                return (
                                                                                    <Chip key={value} label={item.name} />
                                                                                )
                                                                        })

                                                                    ))}
                                                                </Box>
                                                            )}
                                                            MenuProps={MenuProps}
                                                        >
                                                            {func.allGroupe?.map((item, index) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={index}
                                                                        value={item._id}
                                                                    >
                                                                        {item.name}
                                                                    </MenuItem>
                                                                )
                                                            })}

                                                        </Select>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <div className={styles.Select_Flex}>
                                                    <div className={styles.Select_Flex_R}>
                                                        <p className={styles.UserRegister_label} >Region :</p>
                                                        <select onChange={(e) => { setRegion(e.target.value) }} className={styles.UserRegister_Input} >
                                                            <option value={null} selected >Choisissez une region</option>
                                                            {func.allRegion?.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item._id}>{item.name}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <div className={styles.Select_Flex_R} >
                                                        <p className={styles.UserRegister_label} >Groupe :</p>
                                                        <select onChange={(e) => { setGroupe(e.target.value) }} className={styles.UserRegister_Input}>
                                                            {region && !groupe ? <option value={null} selected >Choisissez un groupe</option> : <option value={null} selected >Choisissez une region puis un groupe</option>}
                                                            {func.allGroupe?.map((item, index) => {
                                                                if (region === item.region) {
                                                                    return (
                                                                        <option key={index} value={item._id}>{item.name}</option>
                                                                    )
                                                                }
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                    <Btn Txt={"Enregistrer l'utilisateur"} onClick={register} />
                                </div>
                            </div>
                        </div>
                    </>
                )
                    :
                    null
            }
        </>
    );
}

export default User;