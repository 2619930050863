import React from 'react'

// ? /\ Styles /\
import styles from 'screens/home/styles.module.css';

// ? /\ JSX /\
import LoginBox from 'components/concrete/loginBox';

// ? /\ Redux /\
import { useSelector } from 'react-redux';

function Home() {

    // ? /\ Store /\
    const client = useSelector(state => state.client)
    
    return (
        <>
            <div className={styles.Background}>
                <LoginBox />
            </div>
        </>
    )
}
export default Home;