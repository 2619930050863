import React from "react";

// ? /\ Styles /\
import styles from "./heading.module.css";

// ? /\ Images /\
import lines from "static/img/lines.png";

function Heading(props) {
	
	return (
		<div className={styles.heading}>
			<h2>{props.title}</h2>
			{!props.hideLine ? <img className={styles.line} src={lines} alt="" /> : null}
		</div>
	);
}

export default Heading;
