import React from "react";

// ? /\ Styles /\
import styles from "./icon.module.css";

// ? /\ JSX /\
import NumberTag from "../Tags/NumberTag";

function Icon(props) {

	// ? /\ Props /\
	const { numberTag, size, iconClassName, iconSize, src, onClick, className } = props;

	return (
		<div onClick={onClick} className={styles.container + " " + (onClick ? styles.clickable : "") + " " + (className || "")} style={{ height: size, width: size }}>
			<img className={styles.icon + " " + (iconClassName || "")} src={src || null} alt="" style={{ height: iconSize, width: iconSize }} />
			{numberTag ? <NumberTag>{numberTag}</NumberTag> : null}
		</div>
	);
}

export default Icon;
