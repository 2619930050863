// ? /\ UI Types /\
export const ui = {
	SET_MESSAGE: "set_message", 
	SET_SPINNER: "set_spinner", 
	RESET_MESSAGE: "reset_message",
	SET_RESULT_PAGE: "set_result_page",
	SET_RESULT_PAGE_INDEX: "set_result_page_index"
};

// ? /\ Client Types /\
export const client = {
	LOGIN: "login",
	LOGOUT: "logout"
}

// ? /\ Functionnal Types /\
export const func = {
	GET_ALL_USER: "get_all_user",
	GET_ALL_REGION: "get_all_region",
	GET_ALL_GROUPE: "get_all_groupe",
	GET_ALL_JOB_CAT: "get_all_job_cat",
	GET_ALL_JOB: "get_all_job",
	GET_ALL_IMG: "get_all_img",
	GET_ALL_IMG_EDIT: "get_all_img_edit",
	GET_ALL_CONFIG: "get_all_config"
}