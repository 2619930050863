import React, { useEffect, useState } from 'react';

// ? /\ Styles /\
import styles from 'screens/admin/admin.module.css'

// ? /\ JSX /\
import LeftNav from 'components/concrete/leftNav';
import User from 'components/concrete/User';
import Region from "components/concrete/Region"
import Groupe from 'components/concrete/Groupe';
import Jobs from "components/concrete/Jobs";
import JobsCat from 'components/concrete/JobsCat';
import Image from 'components/concrete/Image';
import OverVeiw from 'components/concrete/OverView'
import Config from 'components/concrete/Config';

// ? /\ Redux /\
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

// ? /\ Router /\
import { useHistory } from 'react-router-dom';

// ? /\ Actions /\
import { getAllInfo } from 'redux/Function/actions';
import { logOut } from 'redux/Client/actions'

function Admin() {

    // ? /\ Store /\
    const client = useSelector(state => state.client);
    const func = useSelector(state => state.fonctionel);

    // ? /\ Other Actions /\
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!client.isAdmin && !client.token) {
            history.push("/")
        } else if (!func.allGroupe || !func.allImg || !func.allJob || !func.allJobCat || !func.allRegion || !func.allUser) {
            dispatch(getAllInfo()); // ? to get all info if we don't have it
        }
    }, [client]);

    // ? /\ State /\
    const [value, setValue] = useState(0);

    // ? /\ Functions /\
    function ChangeRight(val) {
        setValue(val)
    }

    // ? /\ Left Nav List /\
    const list = [
        {
            title: "Vue d'ensemble",
            onClick: () => { ChangeRight(0) }
        },
        {
            title: "Utilisateurs",
            onClick: () => { ChangeRight(1) }
        },
        {
            title: "Regions",
            onClick: () => { ChangeRight(2) }
        },
        {
            title: "Groupes",
            onClick: () => { ChangeRight(3) }
        },
        {
            title: "Métiers",
            onClick: () => { ChangeRight(4) }
        },
        {
            title: "Catégories",
            onClick: () => { ChangeRight(5) }
        },
        {
            title: "Banque d’images",
            onClick: () => { ChangeRight(6) }
        },
        {
            title: "Config",
            onClick: () => { ChangeRight(7) }
        },
        {
            title: "Se déconnecter",
            onClick: () => { dispatch(logOut(history)) }
        }
    ]
    return (
        <>
            <div className={styles.AdminFlex} >
                <LeftNav list={list} />
                {
                    value === 0 ?
                        <OverVeiw />
                        :
                        value === 1 ?
                            <User />
                            :
                            value === 2 ?
                                <Region />
                                :
                                value === 3 ?
                                    <Groupe />
                                    :
                                    value === 4 ?
                                        <Jobs />
                                        :
                                        value === 5 ?
                                            <JobsCat />
                                            :
                                            value === 6 ?
                                                <Image />
                                                :
                                                value === 7 ?
                                                    <Config />
                                                    :
                                                    null
                }
            </div>
        </>
    );
}

export default Admin;