import { func } from "redux/types";

const initialState = {
    allUser: null,
    allRegion: null,
    allGroupe: null,
    allJobCat: null,
    allJob: null,
    allImg: null,
    allImgEdit: null,
    configData: null
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case func.GET_ALL_USER:
            return {
                ...state,
                allUser: action.payload.data
            }
        case func.GET_ALL_REGION:
            return {
                ...state,
                allRegion: action.payload.data
            }
        case func.GET_ALL_GROUPE:
            return {
                ...state,
                allGroupe: action.payload.data
            }
        case func.GET_ALL_JOB_CAT:
            return {
                ...state,
                allJobCat: action.payload.data
            }
        case func.GET_ALL_JOB:
            return {
                ...state,
                allJob: action.payload.data
            }
        case func.GET_ALL_IMG:
            return {
                ...state,
                allImg: action.payload
            } 
        case func.GET_ALL_IMG_EDIT:
            return {
                ...state,
                allImgEdit: action.payload
            }
            case func.GET_ALL_CONFIG:
            return {
                ...state,
                configData: action.payload
            }
        default:
            return state;
    }
}
export default reducer;