import React from 'react'
import { useHistory } from "react-router-dom";

// ? /\ Redux /\
import { useDispatch } from 'react-redux';

// ? /\ Actions /\
import { resetMessage } from 'redux/UI/actions';

// ? /\ Styles /\
import styles from './Message_Box.module.css';

// ? /\ JSX /\
import Heading from 'components/abstract/Heading';
import Btn from 'components/abstract/BTN';
import { logOut } from 'redux/Client/actions';


function MessageBox(props) {

    // ? /\ Props /\
    const { message } = props;
    const history = useHistory()

    // ? /\ Other Actions /\
    const dispatch = useDispatch();

    function reset() {
        if(message === "Nous avons bien pris en compte votre demande les images seront posté sur vos réseaux"){
            dispatch(logOut(history));
            dispatch(resetMessage());
        }else {
            dispatch(resetMessage());
        }
    }
    return (
        <>
            <div className={styles.Message_Box_Main}>
                <div className={styles.Message_Box_Header_And_Text}>
                    <Heading title={"Message"} />
                    <p>{message}</p>
                </div>
                <div className={styles.Message_Box_button}>
                    <Btn className={styles.Message_Box_Btn} Txt={"Ok"} onClick={reset} />
                </div>
            </div>
        </>
    )
}
export default MessageBox;