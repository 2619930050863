import axios from "axios";

// ? /\ Value /\
import { API_LINK } from "configlink";

// ? /\ Types /\
import { func } from "redux/types";

// ? /\ Actions /\
import { setSpinner, setMessage } from "redux/UI/actions";

/* 
* /\ GET All Client, Job, JobCat, Groupe, Region , Image /\
*/
// ? Get all Client
export const GetAllclient = () => dispatch => {
    dispatch(setSpinner(true))
    axios.get(`${API_LINK}user/all`)
        .then((res) => {
            console.log("Clg Client", res)
            dispatch({
                type: func.GET_ALL_USER,
                payload: res.data
            });
            dispatch(setSpinner(false));
        }).catch((err) => {
            console.log(err);
            dispatch(setMessage("Une erreur est survenue lors de la récupération des utilisateurs"));
            dispatch(setSpinner(false));
        })
}

// ? Get all Job 
export const getAllJob = () => dispatch => {
    dispatch(setSpinner(true))
    axios.get(`${API_LINK}job/all`)
        .then((resp) => {
            dispatch(setSpinner(false))
            dispatch({
                type: func.GET_ALL_JOB,
                payload: resp.data
            });
        }).catch((err) => {
            console.log(err);
            dispatch(setMessage("Une erreur est survenue lors de la récupération des Jobs"));
            dispatch(setSpinner(false));
        })
}

// ? Get all JobCat
export const getAllJobCat = () => dispatch => {
    dispatch(setSpinner(true))
    axios.get(`${API_LINK}jobcat/all`)
        .then((res) => {
            dispatch({
                type: func.GET_ALL_JOB_CAT,
                payload: res.data
            })
            dispatch(setSpinner(false));
        }).catch((err) => {
            console.log(err);
            dispatch(setMessage("Une erreur est survenue lors de la récupération des JobsCat"));
            dispatch(setSpinner(false));
        })
}

// ? Get All Groupe 
export const getAllGroupe = () => dispatch => {
    dispatch(setSpinner(true))
    axios.get(`${API_LINK}groupe/all`)
        .then((res) => {
            dispatch({
                type: func.GET_ALL_GROUPE,
                payload: res.data
            });
            dispatch(setSpinner(false));
        }).catch((err) => {
            console.log(err);
            dispatch(setMessage("Une erreur est survenue lors de la récupération des Groupes"));
            dispatch(setSpinner(false));
        })
}

// ? Get all Region 
export const getAllRegion = () => dispatch => {
    dispatch(setSpinner(true))
    axios.get(`${API_LINK}region/all`)
        .then((res) => {
            dispatch({
                type: func.GET_ALL_REGION,
                payload: res.data
            });
            dispatch(setSpinner(false));
        }).catch((err) => {
            console.log(err);
            dispatch(setMessage("Une erreur est survenue lors de la récupération des Regions"));
            dispatch(setSpinner(false));
        })
}

// ? Get all Image
export const getAllImage = () => dispatch => {
    dispatch(setSpinner(true))
    axios.get(`${API_LINK}getimg/all`)
        .then((resp) => {
            const imageArray = []
            resp.data.data.map((item, index) => {
                imageArray.push({
                    url: `${API_LINK}img/${item.fileName}`,
                    id: item._id,
                    name: item.name
                });
            })
            if (imageArray.length === resp.data.data.length) {
                dispatch({
                    type: func.GET_ALL_IMG,
                    payload: imageArray
                });
                dispatch(setSpinner(false));
            }
        }).catch((err) => {
            console.log(err)
            dispatch(setSpinner(false));
            dispatch(setMessage("Une erreur est survenue lors de la récupération des Images"));

        })
}
export const getConfigvalue = () => dispatch => {
    axios.get(`${API_LINK}config/getvalue`).then((resp) => {
        dispatch({
            type: func.GET_ALL_CONFIG,
            payload: resp.data
        });
    })
}
// ? getAll Info For admin
export const getAllInfo = () => dispatch => {
    dispatch(setSpinner(true));
    dispatch(GetAllclient());
    dispatch(getAllGroupe());
    dispatch(getAllImage());
    dispatch(getAllJob());
    dispatch(getAllJobCat());
    dispatch(getAllRegion());
    dispatch(getConfigvalue());
    dispatch(setSpinner(false));
}

// ? get all info For User 
export const getALlInfoForUser = () => dispatch => {
    dispatch(setSpinner(true));
    dispatch(getAllGroupe());
    dispatch(getAllImage());
    dispatch(getAllJob());
    dispatch(getAllJobCat());
    dispatch(getAllRegion());
    dispatch(getConfigvalue());
    dispatch(setSpinner(false));
}

/* 
* /\ Create  Client, Job, JobCat, Groupe, Region , Image /\
*/
// ? Create a client
export const createClient = (email, password, region, isAdmin, username, groupe, surname, name,  token) => dispacth => {
    console.log('in regitser')
    dispacth(setSpinner(true));
    axios.post(`${API_LINK}register/${token}`, {
        email: email,
        password: password,
        region: region,
        isAdmin: isAdmin,
        username: username,
        groupe: groupe
    }).then((resp) => {
        dispacth(GetAllclient());
        dispacth(setMessage(" L’utilisateur a bien été enregistré"));
        dispacth(setSpinner(false));
        dispacth(SendWelcomeEmail(surname, name, password, username, email));
    }).catch((err) => {
        console.log(err);
        dispacth(setMessage("Une erreur est survenue lors de la création du client"));
        dispacth(setSpinner(false));
    })
}
// ? Create a Job
export const createAJob = (name, cat, token) => dispatch => {
    dispatch(setSpinner(true));
    axios.post(`${API_LINK}job/create/${token}`, {
        name: name,
        cat: cat
    })
        .then((resp) => {
            dispatch(getAllJob());
            dispatch(setMessage("Le métier a bien été crée"));
            dispatch(setSpinner(false));
        })
        .catch((err) => {
            console.log(err);
            dispatch(setMessage("Une erreur est survenue lors de la création du Job"));
            dispatch(setSpinner(false));
        })
}
// ? Create a JobCat
export const createAJobCat = (name, token) => dispatch => {
    dispatch(setSpinner(true));
    axios.post(`${API_LINK}jobcat/create/${token}`, {
        name: name
    })
        .then((resp) => {
            dispatch(getAllJobCat());
            dispatch(setMessage("La catégorie a bien été crée"));
            dispatch(setSpinner(false));
        }).catch((err) => {
            console.log(err)
            dispatch(setMessage("Une erreur est survenue lors de la création de la JobCat"));
            dispatch(setSpinner(false));
        })
}
// ? Create a groupe
export const createGroupe = (town, name, region, token) => dispatch => {
    dispatch(setSpinner(true));
    axios.post(`${API_LINK}groupe/create/${token}`, {
        town: town,
        region: region,
        name: name
    }).then((resp) => {
        dispatch(getAllGroupe());
        dispatch(setMessage("Le groupe a bien été enregistrer"));
        dispatch(setSpinner(false));
    }).catch((err) => {
        console.log(err)
        dispatch(setMessage("Une erreur est survenue lors de la création du Groupe"));
        dispatch(setSpinner(false));
    })
}
// ? Create a Region
export const createRegion = (zip, area, name, directeur, token) => dispatch => {
    dispatch(setSpinner(true));
    axios.post(`${API_LINK}region/create/${token}`, {
        zip: zip,
        area: area,
        name: name,
        directeur: directeur
    }).then((resp) => {
        dispatch(getAllRegion());
        dispatch(setMessage("La région a bien été enregistrer"));
        dispatch(setSpinner(false));
    }).catch((err) => {
        console.log(err)
        dispatch(setMessage("Une erreur est survenue lors de la création de la Region"));
        dispatch(setSpinner(false));
    })
}
// ? Create an Image
export const createAnImage = (name, image, token, cat) => dispatch => {
    dispatch(setSpinner(true));
    console.log('test')
    const data = new FormData();
    data.append('file', image);
    axios.post(`${API_LINK}img/create/${token}/${name}/${cat}`, data)
        .then((resp) => {
            dispatch(getAllImage());
            dispatch(setMessage("L'image a bien été crée"));
            dispatch(setSpinner(false));
        })
        .catch((err) => {
            console.log(err)
            dispatch(setMessage("Une erreur est survenue lors de la création de l'Image"));
            dispatch(setSpinner(false));

        })
}


/* 
* /\ DELETE  Client, Job, JobCat, Groupe, Region , Image /\
*/
// ? Delete a Client
export const deleteAClient = (id, token) => dispatch => {
    dispatch(setSpinner(true));
    console.log(id, token)
    axios.delete(`${API_LINK}user/delete/${id}/${token}`).then((res) => {
        dispatch(GetAllclient());
        dispatch(setMessage("L'utilisateur a bien été supprimé"));
        dispatch(setSpinner(false));
    }).catch((err) => {
        console.log(err);
        dispatch(setMessage("Une erreur est survenue lors de la suppresion de l'Utilisateur"));
        dispatch(setSpinner(false));
    })
}
// ? Delete a Job
export const deleteAJob = (id, token) => dispatch => {
    dispatch(setSpinner(true));
    axios.delete(`${API_LINK}job/delete/${id}/${token}`)
        .then((resp) => {
            dispatch(getAllJob());
            dispatch(setMessage("Le métier a bien été supprimé"));
            dispatch(setSpinner(false));
        })
        .catch((err) => {
            console.log(err);
            dispatch(setMessage("Une erreur est survenue lors de la suppresion du Job"));
            dispatch(setSpinner(false));
        })
}
// ? Delete a JobCat
export const deleteAJobCat = (id, token) => dispatch => {
    dispatch(setSpinner(true));
    axios.delete(`${API_LINK}jobcat/delete/${id}/${token}`)
        .then((resp) => {
            dispatch(getAllJobCat());
            dispatch(setMessage("La catégorie a bien été supprimée"))
            dispatch(setSpinner(false));
        })
        .catch((err) => {
            console.log(err);
            dispatch(setMessage("Une erreur est survenue lors de la suppresion de la JobCat"));
            dispatch(setSpinner(false));
        })
}
// ? Delete a Groupe
export const deleteAGroupe = (id, token) => dispatch => {
    dispatch(setSpinner(true));
    axios.delete(`${API_LINK}groupe/dell/${id}/${token}`)
        .then((resp) => {
            dispatch(getAllGroupe());
            dispatch(setMessage("La région a été supprimée"));
            dispatch(setSpinner(false));
        })
        .catch((err) => {
            console.log(err);
            dispatch(setMessage("Une erreur est survenue lors de la suppresion du Groupe"));
            dispatch(setSpinner(false));
        });
}
// ? Delete a Region
export const deleteARegion = (id, token) => dispatch => {
    dispatch(setSpinner(true));
    axios.delete(`${API_LINK}region/dell/${id}/${token}`)
        .then((resp) => {
            dispatch(getAllRegion());
            dispatch(setMessage("La région a été supprimée"));
            dispatch(setSpinner(false));
        })
        .catch((err) => {
            console.log(err);
            dispatch(setMessage("Une erreur est survenue lors de la suppresion de la Region"));
            dispatch(setSpinner(false));
        });
}
// ? Delete An Image
export const deleteAnImage = (id, token) => dispatch => {
    dispatch(setSpinner(true));
    axios.delete(`${API_LINK}img/delete/${id}/${token}`)
        .then((resp) => {
            dispatch(getAllImage());
            dispatch(setMessage("L'image a bien été supprimé"));
            dispatch(setSpinner(false));
        })
        .catch((err) => {
            console.log(err);
            dispatch(setMessage("Une erreur est survenue lors de la suppresion de l'Image"));
            dispatch(setSpinner(false));
        })
}

/* 
* /\ Modify  Client, Job, JobCat, Groupe, Region , Image /\
*/
// ? Modify a Client

export const modifyUser = (id, token, user) => dispatch => {
    dispatch(setSpinner(true));
    axios.patch(`${API_LINK}user/modify/${id}/${token}`, {user})
    .then((resp) => {
        dispatch(GetAllclient());
        dispatch(setMessage("L'utilisateur a bien été modifié"));
        dispatch(setSpinner(false));
    })
    .catch((err) => {
        console.log(err);
        dispatch(setMessage("Une erreur est survenue lors de la modification de l'utilisateur"));
        dispatch(setSpinner(false));
    })
}

// ? Modify a Region
export const modifyRegion = (id, token, region) => dispatch => {
    dispatch(setSpinner(true));
    axios.patch(`${API_LINK}region/modify/${id}/${token}`, {region})
    .then((resp) => {
        dispatch(getAllRegion());
        dispatch(setMessage("La région a bien été modifié"));
        dispatch(setSpinner(false));
    })
    .catch((err) => {
        console.log(err);
        dispatch(setMessage("Une erreur est survenue lors de la modification de la region"));
        dispatch(setSpinner(false));
    })
}

// ? Modify a Groupe
export const modifyGroupe = (id, token, groupe) => dispatch => {
    dispatch(setSpinner(true));
    axios.patch(`${API_LINK}groupe/modify/${id}/${token}`, {groupe})
    .then((resp) => {
        dispatch(getAllGroupe());
        dispatch(setMessage("Le groupe a bien été modifié"));
        dispatch(setSpinner(false));
    })
    .catch((err) => {
        console.log(err);
        dispatch(setMessage("Une erreur est survenue lors de la modification du groupe"));
        dispatch(setSpinner(false));
    })
}

// ? Modify a Job
export const modifyJob = (id, token, job) => dispatch => {
    console.log("this is the jobss", id, token, job)
    dispatch(setSpinner(true));
    axios.patch(`${API_LINK}job/modify/${id}/${token}`, {job})
    .then((resp) => {
        dispatch(getAllJob());
        dispatch(setMessage("Le job a bien été modifié"));
        dispatch(setSpinner(false));
    })
    .catch((err) => {
        console.log(err);
        dispatch(setMessage("Une erreur est survenue lors de la modification du job"));
        dispatch(setSpinner(false));
    })
}
// ? Modify a JobCat
export const modifyJobCat = (id, token, job) => dispatch => {
    dispatch(setSpinner(true));
    axios.patch(`${API_LINK}jobcat/modify/${id}/${token}`, {job})
    .then((resp) => {
        dispatch(getAllJobCat());
        dispatch(setMessage("La catégorie a bien été modifié"));
        dispatch(setSpinner(false));
    })
    .catch((err) => {
        console.log(err);
        dispatch(setMessage("Une erreur est survenue lors de la modification de la catégorie"));
        dispatch(setSpinner(false));
    })
}

export const modifyConfigvalue = (delay, imageNumber) => dispatch => {
    axios.post(`${API_LINK}config/set`, {
        delay: delay, 
        imageNumber: imageNumber
    }).then((resp) => {
        dispatch(getConfigvalue());
    })
}
/* 
* /\ Send email /\
*/

// ?  Send an email 
export const SendWelcomeEmail = (surname, name, password, username, email) => dispatch => {
    dispatch(setSpinner(true));
    axios.post(`${API_LINK}mail/welcome`, {
        surname: surname,
        name: name, 
        password: password,
        username: username,
        email: email
    })
    .then((res) => {
        dispatch(setSpinner(false));
    })
    .catch((err) => {
        console.log(err);
        dispatch(setSpinner(false));
        dispatch(setMessage("Une erreur est survenue lors de l'envoie du mail"));
    })
}