import { client } from "redux/types";
const initialState = {
    id: null,
    email: null,
    region: null,
    isAdmin: null,
    lastUse: null,
    token: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case client.LOGIN:
            console.log("inside login reducer", action.payload)
            return {
                ...state,
                id: action.payload._id,
                email: action.payload.email,
                region: [action.payload.region],
                groupe: [action.payload.groupe],
                lastActivity: action.payload.lastActivity,
                isAdmin: action.payload.isAdmin,
                lastUse: action.payload.lastUse || null,
                token: action.token
            }
            case client.LOGOUT:
            console.log("inside login reducer", action.payload)
            return {
                id: null,
                email: null,
                region: null,
                isAdmin: null,
                lastUse: null,
                token: null,
            }
        default:
            return state;
    }
}
export default reducer;