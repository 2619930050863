import React, { useEffect, useState } from 'react';

// ? /\ Styles /\
import styles from 'screens/client/client.module.css';

// ? /\ Redux /\
import { useDispatch, useSelector } from 'react-redux';

// ? /\ Router /\
import { useHistory } from 'react-router-dom';

// ? /\ Actions /\
import { getALlInfoForUser } from 'redux/Function/actions';
import { imageCreation } from 'redux/Client/actions';

// ? /\ JSX /\
import Btn from 'components/abstract/BTN';

// ? /\ Images /\ 
import bni from 'static/logo/BNI_logo_Red_RGB.png';
import wsf from 'static/logo/logo.png';

function Client() {

    // ? /\ Others Actions /\
    const history = useHistory();
    const dispatch = useDispatch();

    // ? /\ Store /\
    const client = useSelector(state => state.client);
    const func = useSelector(state => state.fonctionel);

    /*  
    * --------- All State --------- *
                                    */

    // ? /\ Groupe & Region State  /\
    const [selectedGroupe, setSelectedGroupe] = useState(null);
    const [selectedRegion, setSelectedRegion] = useState(null);

    // ? /\ Jobs State /\
    const [job, setJob] = useState(null);
    const [job1, setJob1] = useState(null);
    const [job2, setJob2] = useState(null);
    const [job3, setJob3] = useState(null);
    const [job4, setJob4] = useState(null);


    // ? /\ Cat State /\
    const [cat, setCat] = useState(null);
    const [cat1, setCat1] = useState(null);
    const [cat2, setCat2] = useState(null);
    const [cat3, setCat3] = useState(null);
    const [cat4, setCat4] = useState(null);


    useEffect(() => {

        if (!func.allRegion || !func.allGroupe || !func.allJobCat || !func.allJob || !func.allImg || !func.configData) {
            dispatch(getALlInfoForUser());
        }
        if (client.groupe[0].length === 1 && !selectedGroupe) {
            console.log("inside")
            setSelectedRegion(client?.region[0][0]._id);
            setSelectedGroupe(client?.groupe[0][0].name);
        }
    }, [client]);

    // ? Send the Choicie to the API 
    function send() {
        if(job && job1 && job2 &&  job3 && job4){
            dispatch(imageCreation([job, job1, job2, job3, job4], selectedGroupe, [cat, cat1, cat2, cat3, cat4], client.id))
        }else if(job && job1 && job2 &&  job3 && !job4){
            dispatch(imageCreation([job, job1, job2, job3], selectedGroupe, [cat, cat1, cat2, cat3], client.id))
        }else if(job && job1 && job2 &&  !job3 && !job4){
            dispatch(imageCreation([job, job1, job2 ], selectedGroupe, [cat, cat1, cat2], client.id))
        }else if(job && job1 && !job2 &&  !job3 && !job4){
            dispatch(imageCreation([job, job1], selectedGroupe, [cat, cat1], client.id))
        }else if(job && !job1 && !job2 &&  !job3 && !job4){
            dispatch(imageCreation([job], selectedGroupe, [cat], client.id))
        }



    }
    const date = new Date(client.lastUse)
    const diffTime = Math.abs(Date.now() - date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    console.log(7 - diffDays)



    if (func.configData && parseInt(func.configData.delay/86400000) - diffDays  >= 1 && client.lastUse && client.groupe[0].length <= 1) {
        const pcent = 100 /parseInt(func.configData.delay/86400000)
        const width = Math.round(diffDays * pcent);
        return (
            <>
                <div className={styles.Client_Day_Count}>
                    {parseInt(func.configData.delay/86400000) > 1 ?
                      <p className={styles.Client_Day_Count_Text}>Vous avez déjà effectué une demande au cours des {parseInt(func.configData.delay/86400000)} derniers jours. Nous vous invitons à vous reconnecter après ce délai.</p>
                    :
                    <p className={styles.Client_Day_Count_Text}>Vous avez déjà effectué une demande aujourd'hui. Nous vous invitons à vous reconnecter après ce délai.</p>
                    }

                  
                    <p className={styles.Client_Day_Count_Days}>{parseInt(func.configData.delay/86400000) - diffDays} {parseInt(func.configData.delay/86400000) - diffDays > 1 ? "jours restants" : diffDays > 0 ? "jour restant " : "moins d'un jours"}</p>
                    <div className={styles.Client_Day_Count_back_bar}>
                        <div className={styles.Client_Day_Count_Anim} ></div>
                        <div className={styles.Client_Day_Count_Front_Bar} style={{ background: "white", width: `${width}%`, height: "25px", borderRadius: "55px", display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center", color: "#cf2030", fontWeight: "600", zIndex: "100" }}>
                            {width}%
                        </div>
                    </div>
                </div>
            </>
        )
    } else {


        return (
            <>
                <div className={styles.Client_Background}>
                    <div className={styles.Client_Background_IMG}>
                        <div className={styles.Client_Background_IMG_BNI}>
                            <div className={styles.Client_Background_IMG_WSF}>
                                <div className={styles.Client_Main_Box}>
                                    <div className={styles.Client_Main_Box_InsideBox}>
                                        <div className={styles.Client_Main_Box_Top_Select} >
                                            {client.groupe[0].length > 1 ? <h4 className={styles.align_txt}>Choisissez votre regions et votre groupe</h4> : null}

                                            {client.groupe[0].length > 1 ?
                                                (
                                                    <div className={styles.CLient_Main_Box_Select} >

                                                        <select className={styles.select} value={selectedRegion} onChange={(e) => { setSelectedRegion(e.target.value) }} >
                                                            <option value={null} >Choisissez une region</option>
                                                            {
                                                                client.region[0]?.map((item, index) => {
                                                                    return (
                                                                        <option value={item._id} key={index} >
                                                                            {item.name}
                                                                        </option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                        <select className={styles.select} value={selectedGroupe} onChange={(e) => { setSelectedGroupe(e.target.value) }} >
                                                            {selectedRegion ? <option value={null} >Choisissez un groupe</option> : <option value={null} >Choisissez une région puis un groupe</option>}
                                                            {selectedRegion ?
                                                                client.groupe[0]?.map((item, index) => {
                                                                    return (
                                                                        <option value={item.name} key={index} >
                                                                            {item.name}
                                                                        </option>
                                                                    )
                                                                })
                                                                :
                                                                null
                                                            }
                                                        </select>

                                                    </div>
                                                )
                                                : null
                                            }

                                        </div>
                                        <div className={styles.CLient_Main_Box_Mid_Explication}>
                                            {client.groupe[0].length > 1 ? (
                                                <>
                                                    <h4 className={styles.align_txt}>Comment ça marche ?</h4>
                                                    <p className={styles.CLient_Main_Box_Mid_P}>
                                                        Merci de sélectionner jusqu'à 5 métiers que vous recherchez au sein de votre groupe BNI. Nous publierons cette recherche de professionnels à qui transmettre des recommandations sur vos réseaux sociaux !
                                                    </p>
                                                </>
                                            )
                                                :
                                                (
                                                    <>
                                                        <h4 className={styles.align_txt}>Quels métiers recherchez-vous pour le groupe {selectedGroupe ? selectedGroupe : "..."} </h4>
                                                        <p className={styles.CLient_Main_Box_Mid_P}>
                                                            Merci de sélectionner jusqu'à 5 métiers que vous recherchez au sein de votre groupe BNI. Nous publierons cette recherche de professionnels à qui transmettre des recommandations sur vos réseaux sociaux !
                                                        </p>
                                                    </>
                                                )
                                            }
                                        </div>
                                        <div className={styles.CLient_Main_Box_Jobs_Selection}>
                                            <div className={styles.Client_Main_Box_All_Select_Box}>
                                                <select className={styles.select} value={cat} onChange={(e) => { setCat(e.target.value) }}>
                                                    <option>Choisissez une catégorie</option>
                                                    {
                                                        func.allJobCat?.map((item, index) => {
                                                            return (
                                                                <option key={index} value={item._id}>
                                                                    {item.name}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <select className={styles.select} value={job} onChange={(e) => { setJob(e.target.value) }}>
                                                    {cat ? <option>Choisissez le métier souhaiter</option> : <option>Choisissez une catégorie puis le metier</option>}
                                                    {cat ?
                                                        func.allJob?.map((item, index) => {
                                                            if (item.Cat === cat) {
                                                                return (
                                                                    <option key={index} value={item.name}>
                                                                        {item.name}
                                                                    </option>
                                                                )
                                                            } else {
                                                                return null
                                                            }
                                                        })
                                                        :
                                                        null
                                                    }
                                                </select>
                                            </div>
                                            {cat && job ?
                                                <div className={styles.Client_Main_Box_All_Select_Box}>
                                                    <select className={styles.select} value={cat1} onChange={(e) => { setCat1(e.target.value) }}>
                                                        <option>Choisissez une catégorie</option>
                                                        {
                                                            func.allJobCat?.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item._id}>
                                                                        {item.name}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <select className={styles.select} value={job1} onChange={(e) => { setJob1(e.target.value) }}>
                                                        {cat1 ? <option>Choisissez le métier souhaiter</option> : <option>Choisissez une catégorie puis le metier</option>}
                                                        {cat1 ?
                                                            func.allJob?.map((item, index) => {
                                                                if (item.Cat === cat1) {
                                                                    return (
                                                                        <option key={index} value={item.name}>
                                                                            {item.name}
                                                                        </option>
                                                                    )
                                                                } else {
                                                                    return null
                                                                }
                                                            })
                                                            :
                                                            null
                                                        }
                                                    </select>
                                                </div>
                                                : null

                                            }
                                            {cat1 && job1 ?
                                                <div className={styles.Client_Main_Box_All_Select_Box}>
                                                    <select className={styles.select} value={cat2} onChange={(e) => { setCat2(e.target.value) }}>
                                                        <option>Choisissez une catégorie</option>
                                                        {
                                                            func.allJobCat?.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item._id}>
                                                                        {item.name}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <select className={styles.select} value={job2} onChange={(e) => { setJob2(e.target.value) }}>
                                                        {cat2 ? <option>Choisissez le métier souhaiter</option> : <option>Choisissez une catégorie puis le metier</option>}
                                                        {cat2 ?
                                                            func.allJob?.map((item, index) => {
                                                                if (item.Cat === cat2) {
                                                                    return (
                                                                        <option key={index} value={item.name}>
                                                                            {item.name}
                                                                        </option>
                                                                    )
                                                                } else {
                                                                    return null
                                                                }
                                                            })
                                                            :
                                                            null
                                                        }
                                                    </select>
                                                </div>
                                                : null

                                            }
                                            {cat2 && job2 ?
                                                <div className={styles.Client_Main_Box_All_Select_Box}>
                                                    <select className={styles.select} value={cat3} onChange={(e) => { setCat3(e.target.value) }}>
                                                        <option>Choisissez une catégorie</option>
                                                        {
                                                            func.allJobCat?.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item._id}>
                                                                        {item.name}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <select className={styles.select} value={job3} onChange={(e) => { setJob3(e.target.value) }}>
                                                        {cat3 ? <option>Choisissez le métier souhaiter</option> : <option>Choisissez une catégorie puis le metier</option>}
                                                        {cat3 ?
                                                            func.allJob?.map((item, index) => {

                                                                if (item.Cat === cat3) {
                                                                    return (
                                                                        <option key={index} value={item.name}>
                                                                            {item.name}
                                                                        </option>
                                                                    )
                                                                } else {
                                                                    return null
                                                                }
                                                            })
                                                            :
                                                            null
                                                        }
                                                    </select>
                                                </div>
                                                : null

                                            }
                                            {cat3 && job3 ?
                                                <div className={styles.Client_Main_Box_All_Select_Box}>
                                                    <select className={styles.select} value={cat4} onChange={(e) => { setCat4(e.target.value) }}>
                                                        <option>Choisissez une catégorie</option>
                                                        {
                                                            func.allJobCat?.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item._id}>
                                                                        {item.name}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <select className={styles.select} value={job4} onChange={(e) => { setJob4(e.target.value) }}>
                                                        {cat4 ? <option>Choisissez le métier souhaiter</option> : <option>Choisissez une catégorie puis le metier</option>}
                                                        {cat4 ?
                                                            func.allJob?.map((item, index) => {

                                                                if (item.Cat === cat4) {
                                                                    return (
                                                                        <option key={index} value={item.name}>
                                                                            {item.name}
                                                                        </option>
                                                                    )
                                                                } else {
                                                                    return null
                                                                }
                                                            })
                                                            :
                                                            null
                                                        }
                                                    </select>
                                                </div>
                                                : null

                                            }
                                        </div>
                                        <div className={styles.Btn_Box}>
                                            <Btn onClick={send} Txt={"Envoyer"} />
                                        </div>
                                        <p className={styles.Copyright}>Développer par WSF tous les droits réserver à <a href="https://www.wsf.fr/">WSF</a> © 2021 - {new Date().getFullYear()}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.Mobile_Footer}>
                            <p className={styles.Copyright2}>Développer par WSF tous les droits réserver à <a href="https://www.wsf.fr/">WSF</a> ©  2021 - {new Date().getFullYear()}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default Client;