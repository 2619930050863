import React from "react";
// ? /\ Router /\
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";

// ? /\ JSX /\
import Loading from "components/abstract/loader";
import MessageBox from "components/concrete/MessageBox";

// ? /\ Pages /\
import Home from "screens/home";
import Client from "screens/client";
import Admin from "screens/admin";

// ? /\ Redux /\
import { useSelector } from "react-redux";


function App() {

	// ? /\ Store /\
	const ui = useSelector(state => state.ui)

	return (
		<>
			<Router>
				{ui.message ? <MessageBox message={ui.message} /> : null}
				{ui.loading ? <Loading /> : null}
					<Switch>
						<Route path="/client">
							<Client />
						</Route>
						<Route path="/admin">
							<Admin />
						</Route>
						<Route path="/">
							<Home />
						</Route>
					</Switch>
			</Router>
		</>
	);
}

export default App;
