import React from "react";

// ? /\ JSX /\
import { CubeGrid } from "better-react-spinkit";

function Loading(props) {

    return (
        <div>
            <CubeGrid
                size={100}
                duration={"1.4s"}
                style={{ position: "absolute", top: "50%", left: "45%", opacity: 1, zIndex: 1 }}
            />
        </div>
    )
}

export default Loading;
