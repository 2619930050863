import React, { useEffect, useState } from 'react';

// ? /\ Styles /\
import styles from 'components/concrete/Region/user_list.module.css';

// ? /\ JSX /\
import Pagination from 'components/abstract/Pagination';
import LoadingIcon from 'components/abstract/LoadingIcon';
import Btn from 'components/abstract/BTN';

// ? /\ Redux /\
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

// ? /\ React Icons /\
import { IoMdCloseCircle } from 'react-icons/io';

// ? /\ Actions /\
import { deleteARegion, getAllRegion, createRegion, modifyRegion } from 'redux/Function/actions';
import { nextResultPage, prevResultPage, setResultPageIndex, setMessage } from 'redux/UI/actions';


function Region() {

    // ? /\ Store /\
    const func = useSelector(state => state.fonctionel);
    const client = useSelector(state => state.client);
    const ui = useSelector(state => state.ui);

    // ? /\ Other Actions /\
    const dispatch = useDispatch();

    // ? /\ Value /\
    const resultByPage = 9;

    // ? /\ State /\
    const [regionList, setRegionList] = useState();
    const [intialize, setIntialize] = useState();
    const [showPopUp, setShowPopUp] = useState();
    const [showMorePopUp, setShowMorePopUp] = useState(false);
    const [toShow, setToShow] = useState([]);
    const [zip, setZip] = useState("");
    const [area, setArea] = useState("");
    const [name, setName] = useState("");
    const [directeur, setDirecteur] = useState("");
    const [searchInput, setSearchInput] = useState("")

    useEffect(() => {
        if (intialize && regionList !== func.allRegion.slice(ui.resultPageIndexMin, ui.resultPageIndexMax)) {
            console.log("inside where i want 2");
            setRegionList(func.allRegion.slice(ui.resultPageIndexMin, ui.resultPageIndexMax));
        } if (ui.message === "La région a bien été enregistrer") {
            setZip("");
            setArea("");
            setName("");
            setDirecteur("");
        } if (!func.allUser && !intialize) {
            dispatch(getAllRegion());
        } else if (!regionList && func.allRegion && !intialize) {
            setIntialize(true);
            setRegionList(func.allRegion.slice(ui.resultPageIndexMin, ui.resultPageIndexMax));
        } else if (ui.SearchResultPage * resultByPage !== ui.resultPageIndexMax) {
            console.log("inside where i xant");
            dispatch(setResultPageIndex(resultByPage, ui.SearchResultPage));
        }
    }, [func, ui]);

    // ? Register function
    function register() {
        if (!directeur) {
            dispatch(setMessage("Les deux mot de passe ne coresspond pas "));
        } else if (!zip) {
            dispatch(setMessage("Vous n'avez pas défini la region"));
        } else if (!area) {
            dispatch(setMessage("Vous n'avez pas défini l'email"));
        } else {
            dispatch(createRegion(zip, area, name, directeur, client.token));
        }
    }
    // ? Show the popup to modify 
    function showMore(index, value) {
        if (value) {
            setShowMorePopUp(false);
            setToShow([])
        } else if (!value) {
            setShowMorePopUp(true);
            setToShow(func.allRegion[ui.SearchResultPage > 1 ? (ui.SearchResultPage -1) * resultByPage + index: index])
        }
    }

    // ? Show PopUp function
    function FuncShowPopUp() {
        if (showPopUp === true) {
            setShowPopUp(false);
        } else {
            setShowPopUp(true);
        }
    }

    const renderList = regionList?.map((item, index) => {
        return (
            <div className={styles.Region_List} key={index} >
                <p className={styles.Region_Color} onClick={() => { showMore(index, showMorePopUp) }} >{item.name}</p>
                <p className={styles.Region_Color} onClick={() => { showMore(index, showMorePopUp) }} >{item.directeur}</p>
                <p className={styles.Region_Color} onClick={() => { showMore(index, showMorePopUp) }} >{item.area}</p>
                <p className={styles.Region_Color} onClick={() => { showMore(index, showMorePopUp) }} >{item.zip}</p>
                <Btn Txt={"Supprimer"} onClick={() => { dispatch(deleteARegion(item._id, client.token)) }} />
            </div>
        )
    });
    const searchList = func.allRegion?.map((item, index) => {
        if(item.name.toLowerCase().includes(searchInput.toLowerCase())){

            return (
                <div className={styles.Region_List} key={index} >
                <p className={styles.Region_Color} onClick={() => { showMore(index, showMorePopUp) }} >{item.name}</p>
                <p className={styles.Region_Color} onClick={() => { showMore(index, showMorePopUp) }} >{item.directeur}</p>
                <p className={styles.Region_Color} onClick={() => { showMore(index, showMorePopUp) }} >{item.area}</p>
                <p className={styles.Region_Color} onClick={() => { showMore(index, showMorePopUp) }} >{item.zip}</p>
                <Btn Txt={"Supprimer"} onClick={() => { dispatch(deleteARegion(item._id, client.token)) }} />
            </div>
        )
    }
    });
    function ModifyRegionFunc() {
         dispatch(modifyRegion(client.id, client.token, toShow))
    }

    return (
        <>
            <div className={styles.Region_Main}>
                <div className={styles.Region_Box}>
                    <div className={styles.Region_Title}>
                        <div className={styles.Region_Up}>
                            <p className={styles.Region_Title_Txt} >Liste des regions</p>
                            <Btn Txt={"Ajouter une région"} onClick={FuncShowPopUp} />
                        </div>
                        <div className={styles.search_box}>
                         <input className={styles.search_bar} value={searchInput} onChange={(e) => {setSearchInput(e.target.value)}} />
                    </div>
                        <div className={styles.Region_Loading_And_Page}>
                            <LoadingIcon onClick={() => { dispatch(getAllRegion()) }} />
                            <Pagination
                                amountOfPages={Math.ceil(func.allRegion?.length / resultByPage)}
                                currentPage={ui.SearchResultPage}
                                onClickLeft={() => dispatch(prevResultPage(ui.SearchResultPage, Math.ceil(func.allRegion.length / resultByPage), 1))}
                                onClickRight={() => dispatch(nextResultPage(ui.SearchResultPage, Math.ceil(func.allRegion.length / resultByPage), 1))}
                            />
                        </div>
                    </div>
                    
                    <div className={styles.Region_Separator} ></div>
                    <div className={styles.Region_List_Box}>
                        <div className={styles.Region_List} >
                            <p className={styles.Region_Color}>nom</p>
                            <p className={styles.Region_Color}>directeur</p>
                            <p className={styles.Region_Color}>departement</p>
                            <p className={styles.Region_Color}>N° de departement</p>
                            <p className={styles.Region_Color}>Supprimer</p>
                        </div>
                        <div className={styles.Region_Separator} ></div>
                        {searchInput ? searchList : renderList}

                    </div>
                </div>
            </div>
            {showMorePopUp ?
                <>
                    <div className={styles.Region_PopUp_Main_Box}>
                        <div className={styles.Region_PopUp_CloseBtn}> <p className={styles.Region_PopUp_All_Txt}>Panel Utilisateur</p> <IoMdCloseCircle onClick={() => { showMore(0, true) }} color='white' size={30} /> </div>
                        <div style={{ marginTop: "25px" }} className={styles.Region_PopUp_Top_Bar}>
                            <p style={{ marginTop: "25px" }} className={styles.RegionRegister_label} >Nom</p>
                            <input className={styles.RegionRegister_Input2} placeholder="Nom d'utilisateur" type="text" value={toShow.name} onChange={(e) => { setToShow({ ...toShow, name: e.target.value }) }} />
                            <p style={{ marginTop: "25px" }} className={styles.RegionRegister_label} >directeur :</p>
                            <input className={styles.RegionRegister_Input2} placeholder="directeur" type="text" value={toShow.directeur} onChange={(e) => { setToShow({ ...toShow, directeur: e.target.value }) }} />
                            <p style={{ marginTop: "25px" }} className={styles.RegionRegister_label} >departement :</p>
                            <input className={styles.RegionRegister_Input2} placeholder="departement" type="text" value={toShow.area} onChange={(e) => { setToShow({ ...toShow, area: e.target.value }) }} />
                            <p style={{ marginTop: "25px" }} className={styles.RegionRegister_label} >N° de departement :</p>
                            <input className={styles.RegionRegister_Input2} placeholder="N° de departement" type="text" value={toShow.zip} onChange={(e) => { setToShow({ ...toShow, zip: e.target.value }) }} />
                        </div>
                        <div style={{ marginTop: "100px" }} className={styles.Region_PopUp_Person}>
                            <Btn Txt={"Modifier"} onClick={ModifyRegionFunc} />
                        </div>
                    </div>
                </>
                :
                null
            }
            {
                showPopUp ? (
                    <>
                        <div className={styles.Region_PopUp_Main_Box}>
                            <div className={styles.Region_PopUp_CloseBtn}> <p className={styles.Region_PopUp_All_Txt}>Creation Regions</p> <IoMdCloseCircle onClick={() => { setShowPopUp(false) }} color='white' size={30} /> </div>
                            <div className={styles.Region_PopUp_Top_Bar}>
                                <div className={styles.RegionRegister_FBox}>
                                    <div autoComplete='off' className={styles.RegionRegister_Form}>
                                        <div className={styles.RegionRegister_Display}>
                                            <div className={styles.RegionRegister_Flexy} >
                                                <p className={styles.RegionRegister_label} >Numéro de département :</p>
                                                <input className={styles.RegionRegister_Input2} placeholder='Numéro de département (ex 14)' type="numeric" value={zip} onChange={(e) => { setZip(e.target.value) }} />
                                            </div>
                                            <div className={styles.RegionRegister_Flexy} >
                                                <p className={styles.RegionRegister_label} >Département :</p>
                                                <input className={styles.RegionRegister_Input2} placeholder='Département (ex Calvados)' type="text" value={area} onChange={(e) => { setArea(e.target.value) }} />
                                            </div>

                                        </div>
                                        <div style={{ marginBottom: "25px" }}>
                                            <p className={styles.RegionRegister_label} >Nom de la region BNI :</p>
                                            <input className={styles.RegionRegister_Input} placeholder='region BNI' type="text" value={name} onChange={(e) => { setName(e.target.value) }} />
                                        </div>
                                        <div style={{ marginBottom: "25px" }}>
                                            <p className={styles.RegionRegister_label} >Directeur :</p>
                                            <input className={styles.RegionRegister_Input} placeholder='Directeur' type="text" onChange={(e) => { setDirecteur(e.target.value) }} value={directeur} />
                                        </div>
                                        <Btn Txt={"Enregistrer l'utilisateur"} onClick={register} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
                    :
                    null
            }
        </>
    );
}

export default Region;