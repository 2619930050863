import React from 'react';

// ? /\ Styles /\
import styles from 'components/concrete/leftNav/left_nav.module.css'

function LeftNav(props) {

    // ? /\ Props /\
    const { list } = props 
    return (
        <>
            <div className={styles.Back_Box} >
                <div className={styles.Main_Box} >
                    <div className={styles.Up_Nav} >
                        <p className={styles.Nav_Txt}>Panel admin</p>
                    </div>
                    <div className={styles.NavSeparator}></div>
                    {list.map((item, index) => {
                        return (
                            <div className={styles.Up_Nav} key={index} onClick={item.onClick} >
                                <p className={styles.Nav_Txt}>{item.title}</p>
                            </div>
                        )
                    })
                    }
                </div>
            </div>
        </>
    );
}

export default LeftNav;