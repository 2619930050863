import React, { useEffect, useState } from 'react';

// ? /\ Style /\
import styles from 'components/concrete/Config/img_list.module.css';

// ? /\ Redux /\
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

// ? /\ Actions /\
import { deleteAnImage, getAllImage, getAllInfo, createAnImage, getConfigvalue, modifyConfigvalue } from 'redux/Function/actions';
import { setMessage } from 'redux/UI/actions'

// ? /\ JSX /\
import LoadingIcon from 'components/abstract/LoadingIcon';
import Btn from 'components/abstract/BTN';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

// ? /\ React Icons /\
import { FcOvertime } from 'react-icons/fc';

// ? /\ React Icons /\
import { FaRegImages } from 'react-icons/fa'

// ? /\ React Icons /\
import { BiDownArrowAlt } from 'react-icons/bi'

function Config() {

    // ? /\ Store /\
    const func = useSelector(state => state.fonctionel);
    const client = useSelector(state => state.client);
    const ui = useSelector(state => state.ui)

    // ? /\ Other Actions /\
    const dispatch = useDispatch();

    // ? /\ State /\
    const [number, setNumber] = useState(0);
    const [showPopUp, setShowPopUp] = useState()
    const [delay, setDelay] = useState("")
    const [imageNumber, setImageNumber] = useState(null)

    useEffect(() => {
        if (!func.configData) {
            dispatch(getAllInfo()); // ? to get all info if we don't have it
        } 
    }, [func, ui]);

    function modify(){
        dispatch(modifyConfigvalue(delay, imageNumber))
    }
    return (
        <>
            <div className={styles.Image_Main}>
                <div className={styles.Image_Box}>
                    <div className={styles.Image_Title}>
                        <div className={styles.Image_Up}>
                            <p className={styles.Image_Title_Txt} >Configuration</p>
                        </div>
                        <div className={styles.Image_Loading_And_Page}>
                            <LoadingIcon onClick={() => { dispatch(getConfigvalue()) }} />
                        </div>
                    </div>
                    <div className={styles.Image_Separator} ></div>
                    <div className={styles.all_config}>
                        <div className={styles.show_config}>
                        <div className={`${styles.OverView_Box_Recap } ${styles.OverView_Box_Bg_Color3}`}>
                            <div className={`${styles.OverView_Box_Recap_Inside}`}>
                                <div>
                                    <h4>délai</h4>
                                    <p>{parseInt(func.configData.delay)/86400000} jours</p>
                                </div>
                                <div className={styles.OverView_Box_Recap_Icon}>
                                    <FcOvertime color='white' size={45} />
                                </div>
                            </div>
                            <p>délai d'attente entre chaque images.</p>
                        </div>
                        <div className={`${styles.OverView_Box_Recap} ${styles.OverView_Box_Bg_Color4}`}>
                            <div className={`${styles.OverView_Box_Recap_Inside}`}>
                                <div>
                                    <h4>Nombre d'images</h4>
                                    <p>{func.configData.imageNumber}</p>
                                </div>
                                <div className={styles.OverView_Box_Recap_Icon}>
                                    <FaRegImages color='white' size={45} />
                                </div>
                            </div>
                            <p>Nombre d'images par catégorie métiers.</p>
                        </div>
                        </div>
                        <Accordion className={styles.Accordion}>
                        <AccordionSummary
          expandIcon={<BiDownArrowAlt color='white' size={35}/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >

                       <p className={styles.Image_Title_Txt}>modifié la config</p>
                       </AccordionSummary>
            <AccordionDetails>
                       <div className={styles.input_and_label_boc}>
                       <p className={styles.label}>Délai en jours :</p>
                       <input className={styles.ImageRegister_Input} placeholder="Nombre de jours" value={delay/86400000} onChange={(e) => {setDelay(e.target.value*86400000)}}  />
                       </div>
                       <div className={styles.input_and_label_boc}>
                       <p className={styles.label}>Nombre d'images par catégorie :</p>
                       <input className={styles.ImageRegister_Input} placeholder="Nombre d'images" value={imageNumber} onChange={(e) => {setImageNumber(e.target.value)}}  />
                       </div>
                       <div className={styles.Flex_Center}>
                        <Btn Txt={"Enregistrer la nouvelle config"} onClick={modify} />
                       </div>
            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Config;