import React from 'react';

// ? /\ Styles /\
import styles from 'components/abstract/LoadingIcon/LoadingIcon.module.css'

// ? /\ Image /\
import load from "static/img/Refresh_icon.png"

function LoadingIcon(props) {

    // ? /\ Props /\
    const { img, onClick } = props
    return (
        <>
            <img src={img ? img : load} alt="The loading icon" className={styles.Loader_Icon} onClick={onClick} />
        </>
    );
}

export default LoadingIcon;