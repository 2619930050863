import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

// Actions : 
import { LogClientIn } from "redux/Client/actions";

// Styles : 
import styles from 'components/concrete/loginBox/styles.module.css';

// Images : 
import logo from "static/logo/logo.png"
import BNI from 'static/logo/BNI_logo_Red_RGB.png'

function LoginBox() {
    // ? /\ Others Actions /\
    const history = useHistory()
    const dispatch = useDispatch()

    // ? /\ State /\
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    
    function login() {
        if (username && password) {
            dispatch(LogClientIn(username, password, history))
        }
    }
    return (
        <>
            <div className={styles.LogBox_Bg}>
                <div className={styles.LogBox_leftSide} >
                    <div className={styles.LogBox_leftSide_LogoBox}>
                        <img src={logo} className={styles.LogBox_WSF_Logo} alt="Logo-WSF-Web-Shopping-France" />
                        <img src={BNI} className={styles.LogBox_BNI_Logo} alt="Logo BNI" />
                    </div>
                    <div className={styles.LogBox_leftSide_TxtBox} >
                        <p className={styles.LogBox_leftSide_Txt} >L’agence web WSF met à votre disposition cette plateforme vous permettant de nous suggérer les métiers que vous recherchez pour vos groupes.</p>
                        <p className={styles.LogBox_leftSide_Txt} >Pour le reste, WSF s’occupe de tout et publiera ces recherches sur vos réseaux sociaux de groupe ! </p>
                        <p className={styles.LogBox_leftSide_Txt_Small}>Développer par WSF tous les droits réserver à <a href="https://www.wsf.fr/">WSF</a> © 2021 - {new Date().getFullYear()}</p>
                    </div>
                </div>
                <div className={styles.LogBox_RightSide} >
                    <p className={styles.LogBox_RightSide_Title}>Se connecter</p>
                    <div className={styles.LogBox_RightSide_InputBox}>
                        <input value={username} onChange={(e) => { setUsername(e.target.value) }} placeholder=" " type="text" className={styles.LogBox_RightSide_Input} />
                        <label className={styles.LogBox_RightSide_Label} >Identifiant</label>
                    </div>
                    <div className={styles.LogBox_RightSide_InputBox}>
                        <input value={password} onChange={(e) => { setPassword(e.target.value) }} placeholder=" " type="password" className={styles.LogBox_RightSide_Input} />
                        <label className={styles.LogBox_RightSide_Label}>Mot de passe</label>
                    </div>
                    <button className={styles.LogBox_RightSide_BTN} onClick={login} >Se connecter</button>
                </div>
            </div>
            

        </>
    )
}
export default LoginBox;