import React, { useEffect, useState } from 'react';

// ? /\ Styles /\
import styles from 'components/concrete/OverView/OverView.module.css';

// ? /\ Redux /\

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

// ? /\ Actions /\
import { getAllInfo } from 'redux/Function/actions';

// ? /\ React-Icons /\

import { SiOpenstreetmap, } from 'react-icons/si';
import { CgUserList } from 'react-icons/cg';
import { TiGroup } from 'react-icons/ti';
import { MdWork } from 'react-icons/md';
import { BiCategory } from 'react-icons/bi';
import { FaImages } from 'react-icons/fa';

function OverVeiw() {

    // ? /\ Store /\
    const func = useSelector(state => state.fonctionel);

    // ? /\ Other fuctions /\
    const dispatch = useDispatch();
    
    useEffect(() => {
        if (!func.allGroupe || !func.allImg || !func.allJob || !func.allJobCat || !func.allRegion || !func.allUser) {
            dispatch(getAllInfo()); // ? to get all info if we don't have it
        }
    }, [func]);

    return (
        <>
            <div className={styles.OverView_Main}>
                <div className={styles.OverView_Box}>
                    <div className={styles.OverView_Box_Recap_Flex}>
                        <div className={`${styles.OverView_Box_Recap} ${styles.OverView_Box_Bg_Color}`}>
                            <div className={`${styles.OverView_Box_Recap_Inside}`}>
                                <div>
                                    <h4>Utilisateurs</h4>
                                    <p>{func.allUser?.length}</p>
                                </div>
                                <div className={styles.OverView_Box_Recap_Icon}>
                                    <CgUserList color='white' size={45} />
                                </div>
                            </div>
                            <p>Nombre d'utilisateurs enregistré</p>
                        </div>
                        <div className={`${styles.OverView_Box_Recap} ${styles.OverView_Box_Bg_Color2}`}>
                            <div className={`${styles.OverView_Box_Recap_Inside}`}>
                                <div>
                                    <h4>Regions</h4>
                                    <p>{func.allRegion?.length}</p>
                                </div>
                                <div className={styles.OverView_Box_Recap_Icon}>
                                    <SiOpenstreetmap color='white' size={45} />
                                </div>
                            </div>
                            <p>Nombre de régions BNI enregistré</p>
                        </div>
                    </div>
                    <div className={styles.OverView_Box_Recap_Flex}>
                        <div className={`${styles.OverView_Box_Recap} ${styles.OverView_Box_Bg_Color3}`}>
                            <div className={`${styles.OverView_Box_Recap_Inside}`}>
                                <div>
                                    <h4>Groupes</h4>
                                    <p>{func.allGroupe?.length}</p>
                                </div>
                                <div className={styles.OverView_Box_Recap_Icon}>
                                    <TiGroup color='white' size={45} />
                                </div>
                            </div>
                            <p>Nombre de groupes enregistré</p>
                        </div>
                        <div className={`${styles.OverView_Box_Recap} ${styles.OverView_Box_Bg_Color4}`}>
                            <div className={`${styles.OverView_Box_Recap_Inside}`}>
                                <div>
                                    <h4>Métiers</h4>
                                    <p>{func.allJob?.length}</p>
                                </div>
                                <div className={styles.OverView_Box_Recap_Icon}>
                                    <MdWork color='white' size={45} />
                                </div>
                            </div>
                            <p>Nombre de métiers enregistré</p>
                        </div>
                    </div>
                    <div className={styles.OverView_Box_Recap_Flex}>
                        <div className={`${styles.OverView_Box_Recap} ${styles.OverView_Box_Bg_Color5}`}>
                            <div className={`${styles.OverView_Box_Recap_Inside}`}>
                                <div>
                                    <h4>Catégories métiers</h4>
                                    <p>{func.allJobCat?.length}</p>
                                </div>
                                <div className={styles.OverView_Box_Recap_Icon}>
                                    <BiCategory color='white' size={45} />
                                </div>
                            </div>
                            <p>Nombre de catégories métiers enregistré</p>
                        </div>
                        <div className={`${styles.OverView_Box_Recap} ${styles.OverView_Box_Bg_Color6}`}>
                            <div className={`${styles.OverView_Box_Recap_Inside}`}>
                                <div>
                                    <h4>Images</h4>
                                    <p>{func.allImg?.length}</p>
                                </div>
                                <div className={styles.OverView_Box_Recap_Icon}>
                                    <FaImages color='white' size={45} />
                                </div>
                            </div>
                            <p>Nombre d'images enregistré</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default OverVeiw;