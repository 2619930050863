import React from "react";

// ? /\ Dom /\
import ReactDOM from "react-dom";

// ? /\ Styles /\
import "./globals.css";

// ? /\ JSX /\
import App from "./App";

// ? /\ Redux /\
import { Provider } from "react-redux";

// ? /\ AllStore /\
import store from "redux/configStore";

// ? /\ Service Worker /\
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById("root")
);
serviceWorkerRegistration.register();
